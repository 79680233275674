import React from "react";

function NoSelectedOrder() {
  return (
    <div className="no-selected-order">
      <p className="message">
        Les détails d'une commande sélectionnée s'affichent ici.{" "}
      </p>
    </div>
  );
}

export default NoSelectedOrder;
