import React from "react";
import {  Alert} from "reactstrap";

import CopyRight from "../copyRight/copyRight";
import "./login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCheck } from "@fortawesome/free-solid-svg-icons";

const CodeVerify = () => {
    return (
    <div className="container-email-change">
      <h5 className="mb-3">
        <FontAwesomeIcon className="text-success" icon={faEnvelope} /> Email
        envoyé <FontAwesomeIcon className="text-success" icon={faCheck} />
      </h5>

      <div className="form-email-verify" >
        <Alert color="success" className="mt-2">
          Une demande de mot de passe oublié vient d'être effectuée. Si vous
          n'avez pas encore reçu l'e-mail de réinitialisation, il ne devrait pas
          tarder à arriver dans votre boîte mail !
        </Alert>
      </div>
      <CopyRight color="rgba(0,0,0,0.6)" />
    </div>
  );
};

export default CodeVerify;
