import React from 'react'
import { Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function EmptyListHistory() {
    return (
        <div className="p-5">
            <Alert color="warning"><FontAwesomeIcon icon={faInfoCircle}/> Pas de commandes dans l'historique</Alert>
        </div>
    )
}

export default EmptyListHistory
