import React, {useState, useEffect} from "react";
import { Container, Badge, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import "./business.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux"
import {useFirestoreConnect, isLoaded, isEmpty} from "react-redux-firebase"
import ChartBusiness from "./chartBusiness";

const Business = () => {
  const [totalMoney, settotalMoney] = useState(0)
  const [numberOfOrders, setnumberOfOrders] = useState(0)
  const [deliveredOrders, setdeliveredOrders] = useState([])
  useFirestoreConnect(()=>[
    {
      collection:"restaurants",
      doc: localStorage.getItem("restaurantID"),
      subcollections: [{ collection: "orders" }],
      storeAs: "orders"
    }
  ])

  const orders = useSelector(state => state.firestore.ordered.orders)

  useEffect(() => {
    orders && setdeliveredOrders(orders.filter(order=>order.status==="delivered"))
    return () => {
      
    }
  }, [orders])
  return (
    <Container className="container-business py-2" fluid>
      <div className="header-container-business py-2">
        <h4 className="font-weight-normal mb-0">
          <FontAwesomeIcon icon={faChartLine} /> Analyse du business
        </h4>
      </div>
      <div className="business-dashboard container-fluid">
        <div className="row row-sals py-2">
          <div className="col-3">
          <h6 className="mt-2">{orders && <Badge className="number-orders">{numberOfOrders}</Badge>} Commandes</h6>
            <div className="total-money-div">
                <h4 className="total-value">{totalMoney}<span> CDF</span></h4>
                <small>Total net</small>
            </div>
            <Badge className="mt-3 py-1">Top 5 des repas</Badge>
            <div className="top-dishes-div">
                <ListGroup flush >
                    <ListGroupItem className="py-1 "><span className="font-weight-bold">20</span> Libumba</ListGroupItem>
                    <ListGroupItem className="py-1"><span className="font-weight-bold">17</span> Piza</ListGroupItem>
                    <ListGroupItem className="py-1"><span className="font-weight-bold">11</span> Riz & haricot</ListGroupItem>
                    <ListGroupItem className="py-1"><span className="font-weight-bold">7</span> Viande aux oignons</ListGroupItem>
                    <ListGroupItem className="py-1"><span className="font-weight-bold">4</span> Spagetti & fromage</ListGroupItem>
                </ListGroup>
            </div>
          </div>
          <div className="col-9">
            {
            !isLoaded(orders) ?<div className="spinner-chart-business"><Spinner color="warning"/></div> : <ChartBusiness settotalMoney={settotalMoney} orders={deliveredOrders} setnumberOfOrders={setnumberOfOrders}/>
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Business;
