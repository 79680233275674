import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { useSelector } from "react-redux";
import {
  ReactReduxFirebaseProvider,
  isLoaded,
  isEmpty
} from "react-redux-firebase";
import { store, rrfProps } from "../store";
import Login from "./Login/login";
import Main from "./main";
import PrivateRoute from "./privateRoute";
import { Spinner } from "reactstrap";
import EditPassword from "./Login/editPassword";
import Success from "./Login/success";
import WarningRestaurantBloqued from "./Login/warningRestaurantBloqued";

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Spinner type="grow" style={{ height: "64px", width: "64px", backgroundColor:"orange" }} />
      </div>
    );
  return children;
}

const Routes = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/password-forgotten" component={EditPassword}/>
            <Route path="/success" component={Success}/>
            <Route path="/error" component={WarningRestaurantBloqued}/>
            <AuthIsLoaded>
              <PrivateRoute path="/d">
                <Main />
              </PrivateRoute>
            </AuthIsLoaded>
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default Routes;
