import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useFirestore } from "react-redux-firebase";
import { useForm } from "react-hook-form";
import Toast from "../toast/toast";
import "./contact.scss";

const Contact = () => {
  const { register, handleSubmit, reset } = useForm();
  const [isSave, setisSave] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setisError] = useState(false);
  const db = useFirestore();

  const onSubmit = data => {
    setisSave(true);
    db.add("contactMessages", {
      ...data,
      restaurantId: localStorage.getItem("restaurantID")
    }).then(res => {
      reset()
      setisSave(false);
      toggleToastSuccess()
    }).catch(err => {
      setisSave(false);
      toggleToastError()
    });
  };

  const toggleToastSuccess = () => {
    setisSuccess(!isSuccess);
  };
  const toggleToastError = () => {
    setisError(!isError);
  };
  return (
    <Container className="pt-1 pb-2 container-contact" fluid>
      <h4 className="text-left font-weight-normal">Contacter nous</h4>
      <Row className="px-2">
        <Col sm="12" md="4" className="bg-white py-3">
          <div className="contact-notice text-center">
            <FontAwesomeIcon icon={faInfoCircle} size="3x" id="warning-icon" />
            <p className="text-muted">
              Veillez nous contacter en cas d'un problème technique ou autre
              situation cadrant avec le système TayariFood!
            </p>
            <br />
            <p className="text-muted">
              Vous pouvez aussi nous appeler au{" "}
              <span className="font-weight-bold">0978308679</span>
              <br />
              ou au <span className="font-weight-bold">0978154329</span>
            </p>
          </div>
        </Col>
        <Col sm="12" md="8" id="col-form" className="pt-2">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label size="sm" className="font-weight-bold">
                Objet du message
              </Label>
              <Input
                type="text"
                name="object"
                innerRef={register({ required: true, minLength: 4 })}
                disabled={isSave ? true : false}
              />
            </FormGroup>
            <FormGroup>
              <Label size="sm" className="font-weight-bold">
                Votre message
              </Label>
              <Input
                type="textarea"
                rows="10"
                name="message"
                disabled={isSave ? true : false}
                innerRef={register({ required: true, minLength: 10 })}
              />
            </FormGroup>
            <div className="text-right ">
              <Button
                className="px-4 mb-3 font-weight-bold"
                size="sm"
                color="success"
                type="submit"
                disabled={isSave ? true : false}
              >
                {isSave && (
                  <Spinner style={{ height: "16px", width: "16px" }} />
                )}{" "}
                Envoyer
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

      <Toast
        className="bg-success text-white"
        isShow={isSuccess}
        toggle={toggleToastSuccess}
        message="Message envoyé"
        delay={4000}
        icon="check"
      />
      <Toast
        className="bg-danger text-white"
        isShow={isError}
        toggle={toggleToastError}
        message="Erreur"
        delay={4000}
        icon=""
      />
    </Container>
  );
};

export default Contact;
