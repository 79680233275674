import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Spinner,
  CustomInput
} from "reactstrap";
import { useFirestore } from "react-redux-firebase";
import Gallery from "./gallery";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Animated } from "react-animated-css";
import ToastMessage from "../toast/toast";

const animatedSelectOption = makeAnimated();

const NewDishForm = ({
  categories,
  toggle,
  gallery,
  toggleToastSuccess,
  toggleToastDanger,
  isOpenEditDish,
  dishToEdit
}) => {
  const { register, handleSubmit } = useForm();
  const [isOpenGallery, setisOpenGallery] = useState(false);
  const [imageUrl, setimageUrl] = useState();
  const [currentCategories, setcurrentCategories] = useState([]);
  const [optionsCategories, setoptionsCategories] = useState([]);
  const [defaultCategories, setdefaultCategories] = useState([])
  const [isSave, setisSave] = useState(false);
  const [showDanger, setshowDanger] = useState(false)
  const db = useFirestore();

  const handleShowDanger = () =>{
    setshowDanger(!showDanger)
  }

  const onSubmit = data => {
    const idResto = localStorage.getItem("restaurantID");
    setisSave(true);
    console.log(data);
    if(!isOpenEditDish){
     if(imageUrl){
    db.add(`restaurants/${idResto}/menu`, {
      ...data,
      imageUrl: imageUrl,
      categories: currentCategories,
      createAt: db.Timestamp.now().toMillis()
    })
      .then(res => {
        setisSave(false);
        toggleToastSuccess();
        toggle();
        console.log(idResto);
      })
      .catch(err => {
        toggleToastDanger();
        setisSave(false);
        console.log("Une erreur", err.message);
      })}else{
        setisSave(false);
        setshowDanger(true)
      }
    }else{
      db.update(`restaurants/${idResto}/menu/${dishToEdit.id}`, {
        ...data,
        imageUrl: imageUrl,
        categories: currentCategories,
        createAt: db.Timestamp.now().toMillis()
      }).then(res => {
        setisSave(false);
        toggleToastSuccess();
        toggle();
        console.log(idResto);
      })
      .catch(err => {
        toggleToastDanger();
        setisSave(false);
        console.log("Une erreur", err.message);
      });
    }
  };

  const toggleGallery = () => {
    setisOpenGallery(!isOpenGallery);
  };
  const getImageUrl = url => {
    setimageUrl(url);
    setisOpenGallery(false);
  };
  const handleChangeCategories = value => {
    const newCategories = [];
    value && value.forEach(val => newCategories.push(val.value));
    setcurrentCategories(newCategories);
  };

  useEffect(() => {
    const options = [];

    categories &&
      categories.forEach(category => {
        options.push({ value: category.category, label: category.category });
      });

    setoptionsCategories(options);

    if (isOpenEditDish) {
      setimageUrl(dishToEdit.imageUrl)
      const dftCategories = [];
      dishToEdit.categories.forEach(category => {
        dftCategories.push({ value: category, label: category });
      });
      setdefaultCategories(dftCategories);
    }
    return () => {};
  }, [categories, dishToEdit, isOpenEditDish]);

console.log(defaultCategories)
  return (
    <Animated animationIn="fadeIn" animationInDelay={10}>
      <div className="newDish-form overflow-auto pb-3 pt-1">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="header-newDish px-3">
            <h3 className="font-weight-normal">
              {!isOpenEditDish
                ? "Nouveau repas"
                : `Modification ${dishToEdit.name}`}
            </h3>
            <Label className="font-weight-bold my-0 mr-2">status:</Label>
            <CustomInput
              type="switch"
              id="status"
              name="status"
              disabled={isSave ? true : false}
              label=""
              defaultChecked={
                isOpenEditDish ? (dishToEdit.status ? true : false) : true
              }
              innerRef={register}
            />
          </div>
          <div className="px-3 pt-2">
            <Row form>
              <Col md="8">
                <FormGroup>
                  <Label className="font-weight-bold my-0" size="sm">
                    <span className="text-success">* </span>Nom du plat
                  </Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    defaultValue={isOpenEditDish ? dishToEdit.name : ""}
                    name="name"
                    innerRef={register({ required: true, minLength: 2 })}
                    disabled={isSave ? true : false}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="font-weight-bold my-0">Categorie (s)</Label>
                  <Select
                    defaultValue={[optionsCategories[1]]}
                    name="categories"
                    options={optionsCategories}
                    placeholder="Sélectionner ..."
                    components={animatedSelectOption}
                    isMulti
                    isDisabled={isSave ? true : false}
                    onChange={handleChangeCategories}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="font-weight-bold my-0" size="sm">
                    Description
                  </Label>
                  <Input
                    type="textarea"
                    bsSize="sm"
                    rows="2"
                    name="details"
                    defaultValue={isOpenEditDish ? dishToEdit.details : ""}
                    innerRef={register}
                    disabled={isSave ? true : false}
                  />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold my-0" size="sm">
                        <span className="text-success">* </span>Prix (Fc)
                      </Label>
                      <div className="d-flex">
                        <div>
                          <Input
                            type="number"
                            bsSize="sm"
                            name="price"
                            defaultValue={
                              isOpenEditDish ? dishToEdit.price : null
                            }
                            innerRef={register({ required: true, min: 0 })}
                            min="0"
                            disabled={isSave ? true : false}
                          />
                        </div>
                        <Label className=" ml-2" size="sm">
                          <span className="font-weight-bold text-muted">CDF</span>
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold my-0" size="sm">
                        <span className="text-success">* </span>Prix équivalent
                        ($)
                      </Label>
                      <div className="d-flex">
                        <div>
                          <Input
                            type="number"
                            step="0.01"
                            bsSize="sm"
                            name="priceEquiv"
                            defaultValue={
                              isOpenEditDish ? dishToEdit.priceEquiv : null
                            }
                            innerRef={register({ required: true, min: 0 })}
                            min="0.00"
                            disabled={isSave ? true : false}
                          />
                        </div>
                        <Label className=" ml-2" size="sm">
                          <span className="font-weight-bold text-muted">USD</span>
                        </Label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold my-0" size="sm">
                        Max pour un client
                      </Label>
                      <div className="max-number-limit">
                        <div className="mr-2">
                          <Input
                            type="number"
                            name="maxLimit"
                            innerRef={register}
                            defaultValue={
                              isOpenEditDish ? dishToEdit.maxLimit : null
                            }
                            bsSize="sm"
                            min="1"
                            disabled={isSave ? true : false}
                          />
                        </div>
                        <Label className="mb-0 text-muted">Plats</Label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold my-0" size="sm">
                        Frais de livraison
                      </Label>
                      <div>
                        <CustomInput
                          type="checkbox"
                          name="deliveryCost"
                          id="condition"
                          defaultChecked={
                            isOpenEditDish
                              ? dishToEdit.deliveryCost
                                ? true
                                : false
                              : false
                          }
                          disabled={isSave ? true : false}
                          innerRef={register}
                          label="Gratuit"
                          inline
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col className="">
                <Label className="font-weight-bold my-0" size="sm">
                  Image du plat
                </Label>
                <div style={{ minHeight: "250px" }}>
                  <img
                    src={!imageUrl ? "/assets/images/dish.png" : imageUrl}
                    width="100%"
                    className="img-dish-placeholder"
                    onClick={!isSave ? toggleGallery : undefined}
                    style={{
                      border: `${
                        !imageUrl
                          ? `dashed 2px ${
                              showDanger ? "red" : "rgba(0, 0, 0, 0.5)"
                            }`
                          : "none"
                      }`,
                    }}
                  />
                </div>
                <div className="text-md-center text-xs-right text-sm-right mt-2">
                  <Button
                    className="px-3 font-weight-bold"
                    size="sm"
                    color="success"
                    type="submit"
                    disabled={isSave ? true : false}
                  >
                    {isSave && (
                      <Spinner style={{ height: "16px", width: "16px" }} />
                    )}{" "}
                    {!isOpenEditDish ? "Enregistrer" : "Mettre à jour"}
                  </Button>
                  <Button
                    className="px-3 ml-2 font-weight-bold"
                    size="sm"
                    onClick={toggle}
                    disabled={isSave ? true : false}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
        <Gallery
          isOpen={isOpenGallery}
          toggle={toggleGallery}
          getImageUrl={getImageUrl}
          gallery={gallery}
        />
        <ToastMessage
          isShow={showDanger}
          toggle={handleShowDanger}
          message="Veillez sélectionner l'image du plat"
          delay={5000}
          icon="times"
          className="bg-danger text-white pr-5 mr-3"
        />
      </div>
    </Animated>
  );
};

export default NewDishForm;
