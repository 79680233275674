import React, { useState } from "react";
import "./navBar.scss";
import { Button } from "reactstrap";
import BurgerButton from "./burgerButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faSignOutAlt,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Detector } from "react-detect-offline";
import ChangeStatusModal from "./changeStatusModal";
import DeconnectModal from "./deconnectModal";

const Header = ({ history, restaurant, toggleSidebar }) => {
  const firebase = useFirebase();
  const db = useFirestore();
  const [isOpenDeconnect, setisOpenDeconnect] = useState(false);
  const [isOpenChangeStatus, setisOpenChangeStatus] = useState(false);
  const [isDeconnect, setisDeconnect] = useState(false);

  const toggleDeconnectModal = () => {
    setisOpenDeconnect(!isOpenDeconnect);
  };
  const logOut = () => {
    setisDeconnect(true);
    db.update(`restaurants/${localStorage.getItem("restaurantID")}`, {
      status: "closed",
    })
      .then((res) => {
        firebase.logout().then((res) => {
          localStorage.removeItem("restaurantID");
          localStorage.removeItem("city");
          setisDeconnect(false);
          history.push("/");
        });
      })
      .catch((err) => {
        setisDeconnect(false);
      });
  };

  const toggleChangeStatus = () => {
    setisOpenChangeStatus(!isOpenChangeStatus);
  };

  return (
    <header className="bg-white">
      <BurgerButton toggleSidebar={toggleSidebar} />

      <Detector
        render={({ online }) =>
          !online ? (
            <div className="no-internet-connexion">
              <FontAwesomeIcon
                icon={faBell}
                className="icon-wifi text-warning mx-2"
                size="1x"
              />
              <div>
                <h6 className="my-0">L'appareil n'est pas connecté</h6>
                <p className="my-0 text-danger">
                  Assurez-vous que votre appareil ait une connexion internet
                  active.
                </p>
              </div>
            </div>
          ) : (
            <h5 className="ml-2">{restaurant && restaurant.name}</h5>
          )
        }
      />
      <div className="blank-space" />
      {restaurant && (
        <div
          className="font-weight-bold mr-1 status-text"
          onClick={toggleChangeStatus}
        >
          <FontAwesomeIcon
            icon={faCircle}
            className={`${
              restaurant.status === "opened" ? "text-success" : "text-danger"
            }`}
          />{" "}
          {restaurant.status === "opened" ? "Ouvert" : "Fermé"}
        </div>
      )}
      <div className="btn-navbar">
        <Button size="sm" className="mx-2" onClick={toggleDeconnectModal}>
          <FontAwesomeIcon icon={faSignOutAlt} size="1x" />
        </Button>
      </div>
      <DeconnectModal
        isOpen={isOpenDeconnect}
        toggle={toggleDeconnectModal}
        logOut={logOut}
        isDeconnect={isDeconnect}
      />
      <ChangeStatusModal
        restaurant={restaurant}
        isOpen={isOpenChangeStatus}
        toggle={toggleChangeStatus}
      />
    </header>
  );
};

export default withRouter(Header);
