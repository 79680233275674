import React, { useState } from "react";
import {
  Card,
  CardFooter,
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { useFirestore } from "react-redux-firebase";
import NoData from "../noData.svg";

const ListDishes = ({ menu, toggleEditDish, toggleToastDelete }) => {
  const [idResto, setidResto] = useState(localStorage.getItem("restaurantID"));
  const [dishToDelete, setdishToDelete] = useState({});
  const [isOpenDeleteDish, setisOpenDeleteDish] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const db = useFirestore();

  const SetToDeleteDish = dish => {
    setisOpenDeleteDish(!isOpenDeleteDish);
    setdishToDelete(dish);
  };
  const deleledish = id => {
    setisDelete(true);
    db.delete(`restaurants/${idResto}/menu/${id}`)
      .then(res => {
        setisDelete(false);
        setisOpenDeleteDish(false);
        toggleToastDelete()
        console.log("deleted");
      })
      .catch(err => {
        setisDelete(false);
        console.log(err.message);
      });
  };

  const changeStatus = (id, status) => {
    db.update(`restaurants/${idResto}/menu/${id}`, {
      status: status ? false : true
    })
      .then(res => {
        console.log("Saved");
      })
      .catch(err => {
        console.log(err.message);
      });
  };
  return (
    <div className="list-dishes overflow-auto">
    <div className="row p-2">
      {menu.length !== 0 ? (
        menu.map(dish => (
          <div key={dish.id} className="col-xs-12 col-sm-6 col-md-3 mb-2">
            <Card className="card-menu-item">
              <div
                onClick={toggleEditDish.bind(this, dish)}
                className="card-menu-img"
                style={{
                  backgroundImage: `url(${dish.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}
              >
                <p className="mb-0 p-1 menu-item-name font-weight-bold">
                  {dish.name}
                </p>
              </div>
              <CardFooter className="footer-menu-item  py-0 px-1">
                <div className="col-price">
                  <p className="m-0 text-muted col-price-text">Prix</p>
                  <p className="m-0 col-price-value font-weight-bold">
                    {dish.price} Fc
                  </p>
                </div>
                <div
                  className={`${
                    dish.status ? "bg-success" : "bg-danger"
                  } btn-dish-short`}
                  style={{
                    height: "16px",
                    width: "16px",
                    borderRadius: "50%"
                  }}
                  onClick={changeStatus.bind(this, dish.id, dish.status)}
                />
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  size="1x"
                  className="m-1 text-muted btn-dish-short"
                  onClick={SetToDeleteDish.bind(this, dish)}
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  size="1x"
                  className="m-1 text-muted btn-dish-short"
                  onClick={toggleEditDish.bind(this, dish)}
                />
              </CardFooter>
            </Card>
          </div>
        ))
      ) : (
        <div className="empty-result">
          <p className="text-muted">Aucun resultat</p>
          <img src={NoData} width="30%" />
        </div>
      )}
      <Modal
        isOpen={isOpenDeleteDish}
        centered
        size="md"
      >
        <ModalBody className="">
          <div
            className="pb-2"
            style={{ fontWeight: "500", fontSize: "1.2rem" }}
          >
            Etes-vous sûr de vouloir supprimer{" "}
            <span className="font-weight-bold text-danger">
              {dishToDelete.name}
            </span>{" "}
            ?
          </div>
          <div className="d-flex">
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="2x"
              className="text-info mr-2"
            />
            <p className="my-0" style={{ fontSize: "0.8rem" }}>
              Une fois vous supprimer ce plat, il ne sera plus accessible par
              vous et tous vos clients. Avant de le supprimer rassurez-vous que
              vous avez pris une bonne décision.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="py-0">
          <Button
            color="success"
            size="sm"
            className="px-3 font-weight-bold"
            disabled={isDelete ? true : false}
            onClick={deleledish.bind(this, dishToDelete.id)}
          >
            {isDelete && (
              <Spinner
                style={{ height: "16px", width: "16px" }}
                color="light"
              />
            )}
            Oui
          </Button>
          <Button
            color="danger"
            outline
            size="sm"
            className="px-3 font-weight-bold"
            onClick={() => setisOpenDeleteDish(false)}
            disabled={isDelete ? true : false}
          >
            Non
          </Button>
        </ModalFooter>
      </Modal>
    </div>
    </div>
  );
};

export default ListDishes;
