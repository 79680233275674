import React, { useState, useEffect } from "react";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { ListGroupItem, ListGroup, Input, Button, Label, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSearch,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
  faClock,
  faPhoneSquareAlt,
  faFilter,
  faFileAlt
} from "@fortawesome/free-solid-svg-icons";
import "./historique.scss";
import DetailsOrderModal from "./detailsOrderModal";
import DetailsOrder from "./detailsOrder";
import moment from "moment";
import LoadingData from "../menu/loadingData";
import EmptyListHistory from "./emptyListHistory";

const ListOrders = () => {
  useFirestoreConnect(() => [
    {
      collection: "restaurants",
      doc: `${localStorage.getItem("restaurantID")}`,
      subcollections: [{ collection: "orders" }],
      //   populate: [{ child: "rider", root: "riders" }],
      storeAs: "orders"
    }
  ]);

  const orders = useSelector(state => state.firestore.ordered.orders);
  const [isOpen, setIsOpen] = useState(false);
  const [order, setOrder] = useState({});
  const [stringToSearch, setstringToSearch] = useState("")
  const [activeOrderId, setactiveOrderId] = useState();
  const [isbyDate, setisbyDate] = useState(false)
  const [oldOrders, setoldOrders] = useState([]);

  const toggle = order => {
    // setIsOpen(!isOpen);
    activeOrderId === order.id
      ? setactiveOrderId()
      : setactiveOrderId(order.id);
  };
  const toggleByDateView = () => {
    setisbyDate(true)
  }

  const onSearch = e =>{
    const {value} = e.target
    setstringToSearch(value)
    setisbyDate(false)
    const searchString = RegExp(value, "i");
    if (orders) {
      setoldOrders(
        orders.filter(
          order => order.status === "rejected" || order.status === "delivered"
        ).filter(order => order.clientName.match(searchString) || order.orderNumber.match(searchString))
      );
    }
  }

  const viewAll = () => {
    if (orders) {
      setoldOrders(
        orders.filter(
          order => order.status === "rejected" || order.status === "delivered"
        )
      );
      setstringToSearch("")
      setisbyDate(false)
    }
  }

  const viewByDate = (e) => {
    const {value} = e.target
    if (orders) {
      setoldOrders(
        orders.filter(
          order => order.status === "rejected" || order.status === "delivered"
        ).filter(order =>  moment(order.createAt).format("DD-MM-YYYY") === moment(value).format("DD-MM-YYYY"))
      );
    }
  }

  useEffect(() => {
    if (orders) {
      setoldOrders(
        orders.filter(
          order => order.status === "rejected" || order.status === "delivered"
        )
      );
    }
    return () => {};
  }, [orders]);



  return (
    <React.Fragment>
      <ListGroup flush className="history-order-list">
        <ListGroupItem className="header-search">
          <div className="search-input-container">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret className="font-weight-bold" size="sm">
                Toutes
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={viewAll}>Toutes</DropdownItem>
                <DropdownItem onClick={toggleByDateView}>Par date</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
            {/* <Button className="font-weight-bold px-3" size="sm" onClick={viewAll}>
              Toutes
            </Button> */}
            {isbyDate && <Input type="date" name="date"bsSize="sm" className="date-filter-input" onChange={viewByDate}/>}
            <Input type="text" name="stringToSearch" value={stringToSearch} bsSize="sm" placeholder="Rechercher ..." onChange={onSearch} />
            
            <Button size="sm">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </div>
        </ListGroupItem>
        <ListGroupItem className="header-list-historique py-0">
          <div className="row">
            <div className="col-3 heading-his font-weight-bold">
              <p className="my-0">Commande</p>
            </div>
            <div className="col-6 heading-his font-weight-bold">
              <p className="my-0">Client</p>
            </div>
            <div className="col-2 heading-his font-weight-bold">
              <p className="my-0 text-center">Status</p>
            </div>
          </div>
        </ListGroupItem>
        {!isLoaded(orders) ? (
          <LoadingData />
        ) : oldOrders.length === 0 ? (
          <EmptyListHistory />
        ) : (
          <div className="fixed-history-order-list overflow-auto">
            {oldOrders.map(order => {
              return (
                <ListGroupItem
                  key={order.id}
                  className={`order-item-historique ${
                    order.id === activeOrderId ? "activeItem" : undefined
                  }`}
                  onClick={
                    order.id !== activeOrderId
                      ? toggle.bind(this, order)
                      : undefined
                  }
                >
                  <div
                    className="row cmd-row "
                    onClick={toggle.bind(this, order)}
                  >
                    <div className="col-3">
                      <div className="cmd-col-commande">
                        <p className="my-0 font-weight-bold cmd-id">
                          #{order.orderNumber}
                        </p>
                        <p className="my-0 cmd-date">
                          <FontAwesomeIcon
                            icon={faClock}
                            // className={
                            //   order.id === activeOrderId
                            //     ? "text-warning"
                            //     : undefined
                            // }
                          />{" "}
                          {moment(order.createAt).format("DD/MM/YYYY HH:mm")}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="cmd-col-client">
                        <p className="cmd-client-name my-0 font-weight-bold">
                          {order.clientName}
                        </p>
                        <p className="cmd-client-phone my-0">
                          <FontAwesomeIcon
                            // className={
                            //   order.id === activeOrderId
                            //     ? "text-warning"
                            //     : undefined
                            // }
                            icon={faPhoneSquareAlt}
                          />{" "}
                          {order.clientPhone}
                        </p>
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="text-center">
                        {order.status === "delivered" ? (
                          <FontAwesomeIcon
                            className="text-success"
                            icon={faCheckCircle}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faTimesCircle}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="text-right">
                        {order.id === activeOrderId ? (
                          <FontAwesomeIcon
                            // className="text-warning"
                            icon={faChevronUp}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="text-muted"
                            icon={faChevronDown}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {order.id === activeOrderId && <DetailsOrder order={order} />}
                </ListGroupItem>
              );
            })}
          </div>
        )}
      </ListGroup>

      <DetailsOrderModal isOpen={isOpen} toggle={toggle} order={order} />
    </React.Fragment>
  );
};

export default ListOrders;
