import React from 'react'
import {ListGroup, ListGroupItem} from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function SecurityInfo() {
    return (
        <div className="security-info">
            <ListGroup flush>
                <ListGroupItem className="security-header">
                    <FontAwesomeIcon icon={faLock}/>   Paramètres de sécurité
                </ListGroupItem>
                <ListGroupItem className="">
                    ***** Changer votre mot de passe <Link to="/password-forgotten">ici</Link>
                </ListGroupItem>
            </ListGroup>
        </div>
    )
}

export default SecurityInfo
