import React from "react";
import { ListGroup, ListGroupItem, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import moment from "moment"

const ListTodeliverOrders = ({ orders, showDetails, activeId }) => {
  return (
    <ListGroup>
      {orders.map(order => (
        <Animated animationIn="slideInLeft" key={order.id}>
          <ListGroupItem
            className={`deliver-order-item mb-1 ${
              order.id === activeId ? "active-item" : null
            }`}
            onClick={showDetails.bind(this, order)}
          >
            <div className="deliver-order-item-info">
              <p className="my-0 font-weight-bold">#{order.orderNumber} {order.paymentStatus ==="success"?<Badge color="success" className="font-weight-normal">Payée</Badge>:<Badge color="danger" className="font-weight-normal">Non payée</Badge>}</p>
              <p className="my-0 time-delivery">
                <FontAwesomeIcon icon={faClock} />A livrer à {moment(order.timeBefore).format("HH:mm")}
              </p>
            </div>
            <div className="">
              <img
                src="/assets/images/scooter.png"
                alt=""
                height="48px"
                width="48px"
              /> 
            </div>
          </ListGroupItem>
        </Animated>
      ))}
    </ListGroup>
  );
};

export default ListTodeliverOrders;
