import React from 'react'
import {Spinner} from "reactstrap"
import "./menu.scss";

export default function LoadingData() {
    return (
        <div className="loading-dishes">
          <Spinner
            type="grow"
            style={{ height: "48px", width: "48px", backgroundColor:"orange" }}
          />
        </div>
    )
}
