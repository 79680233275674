import React from 'react'
import {ReactComponent as Warning} from "./warning.svg"
import "./login.scss"
import { Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function WarningRestaurantBloqued() {
    return (
        <div className="warning-bloqued-restaurant">
            <Warning width="150px" height="160px"/>
            <Alert color="warning" className="text-center mx-2">
                <FontAwesomeIcon icon={faInfoCircle}/> Votre restaurant n'est pas accessible.
                <br/>Il semble que votre restaurant a été bloquer par l'équipe technique de tayarifood.
                <br/>Pour plus d'informations veuillez contacter le service client.
            </Alert>
        </div>
    )
}

export default WarningRestaurantBloqued
