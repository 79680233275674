import React, { useState, useEffect } from "react";
import "./sideBar.scss";
import { ListGroup, ListGroupItem } from "reactstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faQuestionCircle,
  faCog,
  faListAlt,
  faChartLine,
  faHistory,
  faPhoneAlt
} from "@fortawesome/free-solid-svg-icons";
import CopyRight from "../copyRight/copyRight";

const SideBar = ({location,history, show,setViewSidebar, restaurant}) => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(1);
  const [currentPath, setcurrentPath] = useState(location.pathname);

  let sidebarClasses = "sidebar";
  if (show) {
    sidebarClasses = "sidebar open";
  }

  const handleActiveMenu = (index, path) => {
    setActiveMenuIndex(index);
    setcurrentPath(path);
    history.push(path);
    setViewSidebar(false);
  };

  useEffect(() => {
    setcurrentPath(location.pathname)
    return () => {
    };
  }, [location])

  return (
    <div className={`${sidebarClasses} overflow-auto`}>
      <nav>
        <div
          className="resto-banner"
          style={{
            backgroundImage: `url(${restaurant && restaurant.banner})`,
            backgroundRepeat: "none",
            backgroundSize: "cover",
            backgroundColor:"#f9f9f9"
          }}
        ></div>

        <ListGroup flush>
          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d" ? "active-menu": activeMenuIndex === 1 && currentPath ==="/d"
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 1, "/d")}
          >
            <FontAwesomeIcon icon={faHome} className="icon-menu mr-3" />
            Accueil
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/menu"
                ? "active-menu"
                : activeMenuIndex === 2
                ? "active-menu"
                : undefined
            }`}
            onClick= {handleActiveMenu.bind(this, 2, "/d/menu")}
          >
            <FontAwesomeIcon icon={faListAlt} className="icon-menu mr-3" />
            Menu
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/historique"
                ? "active-menu"
                : activeMenuIndex === 3
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 3, "/d/historique")}
          >
            <FontAwesomeIcon icon={faHistory} className="icon-menu mr-3" />
            Historique
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/business"
                ? "active-menu"
                : activeMenuIndex === 4
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 4, "/d/business")}
          >
            <FontAwesomeIcon icon={faChartLine} className="icon-menu mr-3" />
            Business
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath.includes("/d/settings")
                ? "active-menu"
                : activeMenuIndex === 5
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 5, "/d/settings")}
          >
            <FontAwesomeIcon icon={faCog} className="icon-menu mr-3" />
            Paramètres
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/contact"
                ? "active-menu"
                : activeMenuIndex === 6
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 6, "/d/contact")}
          >
            <FontAwesomeIcon
              icon={faPhoneAlt}
              className="icon-menu mr-3"
            />
            Contact
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath.includes("/d/aide")
                ? "active-menu"
                : activeMenuIndex === 7 && currentPath.includes("/d/aide")
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 7, "/d/aide")}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="icon-menu mr-3"
            />
            Aide
          </ListGroupItem>
        </ListGroup>
      </nav>
      <div className="sidebar-blank-space" />
      <CopyRight color="#c3c3c3" />
    </div>
  );
};

export default withRouter(SideBar);
