import React from 'react'
import {Row, Container} from 'reactstrap'
import ListOrders from './listOrders'

const Historique = () => {
    return (
        <Container fluid className="container-historique py-2">
            <h4 className="font-weight-normal">Historique des commandes</h4>
            <Row >
                <div className="col">
                    <ListOrders/>
                </div>
            </Row>
        </Container>
    )
}

export default Historique
