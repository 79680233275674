import React from 'react'
import {Modal, ModalHeader, ModalFooter, ModalBody, Alert, Button, Spinner} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
function DeconnectModal({isOpen, toggle, logOut, isDeconnect}) {
    return (
        <Modal isOpen={isOpen} centered>
        <ModalHeader>
          Déconnexion <FontAwesomeIcon icon={faSignOutAlt} />
        </ModalHeader>
        <ModalBody>
          <Alert color="warning">
            <FontAwesomeIcon icon={faInfoCircle} /> Si vous vous déconnectez,
            votre restaurant sera inaccessible par vos clients depuis
            l'application mobile. C'est-à dire votre restaurant sera consideré
            comme fermé au public.
          </Alert>
          <p className="text-center font-weight-bold text-danger mb-0">
            Etes-vous sûr de vouloir vous déconnecter?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="px-3 font-weight-bold"
            size="sm"
            color="success"
            disabled={isDeconnect ? true : false}
            onClick={logOut}
          >
            {isDeconnect && (
              <Spinner
                style={{ height: "16px", width: "16px" }}
                className="mr-1"
              />
            )}
            Oui
          </Button>
          <Button
            className="px-3 font-weight-bold"
            size="sm"
            disabled={isDeconnect ? true : false}
            onClick={toggle}
          >
            Non
          </Button>
        </ModalFooter>
      </Modal>
    )
}

export default DeconnectModal
