import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col
} from "reactstrap";

import "./menu.scss";

const Gallery = ({ isOpen, toggle, gallery, getImageUrl }) => {
  

  return (
    <Modal isOpen={isOpen} scrollable size="xl">
      <ModalHeader toggle={toggle}>Choisir l'image du plat</ModalHeader>
      <ModalBody>
        <Row>
          {gallery &&
            gallery.map(image => (
              <Col key={image.id} className="col-xs-12 col-sm-6 col-md-3 mb-3">
                <img
                  src={image.imageUrl}
                  height="120px"
                  width="100%"
                  className="img-dish"
                  onClick={getImageUrl.bind(this, image.imageUrl)}
                />
              </Col>
            ))}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="px-3" size="sm" onClick={toggle}>
          Fermer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Gallery;
