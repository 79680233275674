import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Alert,
  ModalFooter,
  Spinner,
  ModalHeader,
  Button,
} from "reactstrap";
import { useFirestore } from "react-redux-firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
function ChangeStatusModal({ restaurant, isOpen, toggle }) {
  const db = useFirestore();
  const [isChange, setisChange] = useState(false);

  const changeRestaurantStatus = () => {
    setisChange(true);
    let status = restaurant.status === "opened" ? "closed" : "opened";
    db.update(`restaurants/${localStorage.getItem("restaurantID")}`, {
      status: status,
    })
      .then((res) => {
        setisChange(false);
        toggle();
      })
      .catch((err) => {
        setisChange(false);
      });
  };
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader>
        {restaurant.status === "opened" ? "Fermeture" : "Ouverture"} du
        restaurant
      </ModalHeader>
      <ModalBody>
        <Alert color="warning">
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {restaurant.status === "opened"
            ? "En fermant le restaurant les clients ne seront plus à mesure de passer des commandes au sein de votre restaurant via l'application mobile."
            : "En ouvrant le restaurant les clients pouront passer des commandes au sein de votre restaurant via l'application mobile."}
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button
          className="px-3 font-weight-bold"
          size="sm"
          color="success"
          disabled={isChange ? true : false}
          onClick={changeRestaurantStatus}
        >
          {isChange && (
            <Spinner
              style={{ height: "16px", width: "16px" }}
              className="mr-1"
            />
          )}
          {restaurant.status === "opened" ? "Fermer" : "Ouvrir"}
        </Button>
        <Button
          className="px-3 font-weight-bold"
          size="sm"
          disabled={isChange ? true : false}
          onClick={toggle}
        >
          Non
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ChangeStatusModal;
