import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPhoneAlt,
  faMapMarkerAlt,
  faBicycle,
} from "@fortawesome/free-solid-svg-icons";
import { ListGroup, ListGroupItem, Badge } from "reactstrap";
import "./home.scss";
import { Animated } from "react-animated-css";
import moment from "moment";

const DeliverOrderDetails = ({ order, rider }) => {
  const [foods, setfoods] = useState([]);
  const [totalPrice, settotalPrice] = useState();

  useEffect(() => {
    let newfoods = [];
    let newTotal = 0;
    order.packages.forEach((food) => {
      newfoods.push(JSON.parse(food));
      newTotal += JSON.parse(food).food.price * JSON.parse(food).count;
    });
    setfoods(newfoods);
    settotalPrice(newTotal);
    return () => {};
  }, [order]);

  return (
    <Animated animationIn="fadeIn">
      <div className="px-3 pt-1 pb-3 order-details overflow-auto">
        <div className="order-details-client-info mt-2">
          <div className="details-name-number ml-2">
            <p className="mb-0 font-weight-bold">{order.clientName}</p>
            <p className="my-0 text-muted order-client-num">
              <FontAwesomeIcon icon={faPhoneAlt} /> {order.clientPhone}
            </p>
          </div>
          <div className="text-right">
            <h6 className="mb-0">
              #{order.orderNumber}{" "}
              {order.paymentStatus === "success" ? (
                <Badge color="success" className="font-weight-normal">
                  Payée
                </Badge>
              ) : (
                <Badge color="danger" className="font-weight-normal">
                  Non payée
                </Badge>
              )}
            </h6>
            <p className="my-0 text-muted order-date">
              <FontAwesomeIcon icon={faClock} />
              Heure de livraison {moment(order.timeBefore).format("HH:mm")}
            </p>
          </div>
        </div>
        <div className="bg-white mt-1 p-3 foods-address-info">
          <div className="">
            <div className="ml-2 py-2 px-1">
              <ListGroup flush>
                {foods.map((food) => (
                  <ListGroupItem className="py-0 bg-transparent  order-details-item">
                    <p className="order-details-item-quantity my-0 text-right font-weight-bold">
                      1x{food.count}
                    </p>
                    <p className="text-muted order-details-item-name my-0 ml-2">
                      {food.food.name}
                    </p>
                    <p className="text-right my-0 ">
                      {food.food.price * food.count}{" "}
                      <span className="font-weight-bold">Fc</span>
                    </p>
                  </ListGroupItem>
                ))}
              </ListGroup>
              <div className="oder-details-total pt-2 pr-3">
                <h6 className="mr-1">Total:</h6>
                <h5 className="oder-details-total-value text-right">
                  {totalPrice} <span className="font-weight-bold">Fc</span>
                </h5>
              </div>
            </div>
          </div>
          <div className="mt-1 ">
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
              <span className="text-success">Adresse de livraison</span>
            </h6>
            <div className="pl-3 py-1 ml-3">
              <p className="text-muted">{order.destination}</p>
            </div>
          </div>

          <div className="mt-2">
            <h6>
              <FontAwesomeIcon icon={faBicycle} />{" "}
              <span className="text-success">Livreur</span>
            </h6>
            <div className="d-flex pl-3 py-2 ml-3">
              <img
                src={rider ? rider.avatar : ""}
                alt=""
                height="48px"
                width="48px"
                className="rounded-circle"
              />
              <div className="ml-2">
                <p className="my-0 font-weight-bold">{rider && rider.name}</p>
                <p className="text-muted my-0 order-rider-num">
                  <FontAwesomeIcon icon={faPhoneAlt} /> {rider && rider.phone}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Animated>
  );
};

export default DeliverOrderDetails;
