import React from "react";
import { ListGroup, ListGroupItem, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css"
import moment from "moment";


const ListPreparingOders = ({ orders, showDetails, activeId }) => {
  
  return (
    <ListGroup>
      {orders.map(order => (
        <Animated key={order.id} animationIn="slideInLeft">
        <ListGroupItem
        
          className={`preparing-order-item mb-1 ${
            order.id === activeId ? "active-item" : null
          }`}
          onClick={showDetails.bind(this, order)}
        >
          <div className="preparing-order-item-info">
            <p className="my-0 font-weight-bold">#{order.orderNumber} {order.paymentStatus ==="success"?<Badge color="success" className="font-weight-normal">Payée</Badge>:<Badge color="danger" className="font-weight-normal">Non payée</Badge>}</p>
            <p className="my-0 time-delivery">
              <FontAwesomeIcon icon={faClock} />Reçue le {moment(order.createAt).format("DD-MM-YYYY à HH:mm")}
            </p>
          </div>
          <div className="">
            <img
              src="/assets/images/prepare.png"
              alt=""
              height="48px"
              width="48px"
            />
          </div>
        </ListGroupItem>
        </Animated>
      ))}
    </ListGroup>
  );
};

export default ListPreparingOders;
