import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore' // <- needed if using firestore
import { createStore, combineReducers, compose } from 'redux'
import {firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const firebaseConfig = {
    apiKey: "AIzaSyBGN-TTKcI3yR6NrdddH8T7oW6MbEfdwko",
    authDomain: "damajefoo.firebaseapp.com",
    databaseURL: "https://damajefoo.firebaseio.com",
    projectId: "damajefoo",
    storageBucket: "damajefoo.appspot.com",
    messagingSenderId: "744474786864",
    appId: "1:744474786864:web:6693be74364d16abd781f5",
    measurementId: "G-6VRYEW3195"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
export const store = createStore(rootReducer, initialState)

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}
