import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  Input,
  Button,
  Spinner,
  ModalFooter,
  ModalHeader,
  Form,
  ListGroupItem,
  ListGroup,
  FormGroup,
  Label,
  Alert,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faClock,
  faInfoCircle,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

function ModalMobileNewOrderDetails({
  isOpen,
  setisOpenModalNewOrderDetails,
  order,
  resetActiveId,
}) {
  const [foods, setfoods] = useState([]);
  const [totalPrice, settotalPrice] = useState();
  const [isSave, setisSave] = useState(false);
  const restoId = localStorage.getItem("restaurantID");
  const [isOpenRefuseModal, setisOpenRefuseModal] = useState(false);
  const [isReject, setisReject] = useState(false);
  const { register, handleSubmit } = useForm();
  const db = useFirestore();

  const acceptOrder = () => {
    setisSave(true);

    db.update(`commands/${order.id}`, {
      status: "accepted",
      restaurantId: localStorage.getItem("restaurantID"),
      timeBefore: order.createAt + 2100000,
    })
      .then((res) => {
        db.update(`restaurants/${restoId}/orders/${order.id}`, {
          status: "accepted",
          restaurantId: localStorage.getItem("restaurantID"),
          timeBefore: order.createAt + 2100000,
        })
          .then((res) => {
            setisSave(false);
            resetActiveId();
          })
          .catch((err) => {
            setisSave(false);
          });
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  const rejectOrder = (data) => {
    setisReject(true);

    db.update(`commands/${order.id}`, {
      rejectReason: data.rejectReason + ". " + data.info,
      status: "rejected",
      restaurantId: localStorage.getItem("restaurantID"),
    })
      .then((res) => {
        db.update(`restaurants/${restoId}/orders/${order.id}`, {
          rejectReason: data.rejectReason + ". " + data.info,
          status: "rejected",
        })
          .then((res) => {
            setisReject(false);
            resetActiveId();
          })
          .catch((err) => {
            setisReject(false);
          });
      })
      .catch((err) => {
        setisReject(false);
      });
  };

  useEffect(() => {
    let newfoods = [];
    let newTotal = 0;
    order.packages.forEach((food) => {
      newfoods.push(JSON.parse(food));
      newTotal += JSON.parse(food).food.price * JSON.parse(food).count;
    });
    setfoods(newfoods);
    console.log(foods);
    settotalPrice(newTotal);
    return () => {};
  }, [order]);

  const toggle = () => {
    setisOpenModalNewOrderDetails(false);
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <div className="details-name-number">
          <h5 className="mb-0">{order.clientName}</h5>
          <p className="my-0 text-muted" style={{ fontSize: "0.7rem" }}>
            <FontAwesomeIcon icon={faPhoneAlt} />
            {order.clientPhone}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="text-right">
          <h6 className="mb-0">
            #{order.orderNumber}{" "}
            {order.paymentStatus === "success" ? (
              <Badge color="success" className="font-weight-normal">
                Payée
              </Badge>
            ) : (
              <Badge color="danger" className="font-weight-normal">
                Non payée
              </Badge>
            )}
          </h6>
          <p className="my-0 text-muted" style={{ fontSize: "0.6rem" }}>
            <Badge color="warning" className="px-1">
              <FontAwesomeIcon icon={faClock} />{" "}
              {moment(order.createAt).format("DD/MM/YYYY HH:mm")}
            </Badge>
          </p>
        </div>

        <div className="py-2">
          <ListGroup flush>
            {foods.map((food) => (
              <ListGroupItem
                key={food.food.foodId}
                className="py-0"
                style={{ display: "flex" }}
              >
                <p className="order-details-item-quantity my-0 text-right font-weight-bold">
                  1x{food.count}
                </p>
                <p className="text-muted my-0 ml-2" style={{ flex: "1" }}>
                  {food.food.name}
                </p>
                <p className="text-right my-0 font-weight-normal">
                  {food.food.price * food.count}
                  <span className="font-weight-bold"> Fc</span>
                </p>
              </ListGroupItem>
            ))}
          </ListGroup>
          <div className="pt-1 pr-3" style={{ display: "flex" }}>
            <h6 className="mb-0">Total:</h6>
            <h5 className="mb-0 text-right" style={{ flex: "1" }}>
              {totalPrice}
              <span className="font-weight-bold"> Fc</span>
            </h5>
          </div>
        </div>
        <div className="mt-1">
          <h6>
            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
            <span className="text-success">Adresse de livraison</span>
          </h6>
          <div className="pl-3 py-1 ml-3">
            <p className="text-muted">{order.destination}</p>
          </div>
        </div>
        <Modal isOpen={isOpenRefuseModal} centered>
          <Form onSubmit={handleSubmit(rejectOrder)}>
            <ModalBody>
              <Alert color="danger">
                <FontAwesomeIcon icon={faInfoCircle} /> Il faut avoir une bonne
                raison de refuser une commande. Veillez fournir la{" "}
                <span className="font-weight-bold text-dark">raison</span> afin
                de notifier le client{" "}
                <span className="font-weight-bold text-dark">{order.name}</span>{" "}
                du refus de sa commande.
              </Alert>
              <FormGroup>
                <Label className="font-weight-bold my-0" size="sm">
                  <span className="text-success">*</span>Raison du refus
                </Label>
                <Input
                  type="select"
                  bsSize="sm"
                  name="rejectReason"
                  innerRef={register({ required: true })}
                >
                  <option></option>
                  <option value="L'adresse de livraison hors zone">
                    Adresse de livraison hors zone d'intervention
                  </option>
                  <option value="Repas non disponible">
                    Repas non disponible
                  </option>
                  <option value="L'adresse de livraison n'est pas precise">
                    Adresse de livraison n'est pas precise
                  </option>
                </Input>
                <Label className="font-weight-bold my-0" size="sm">
                  Information supplémentaire
                </Label>
                <Input
                  type="textarea"
                  name="info"
                  bsSize="sm"
                  innerRef={register}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="font-weight-bold px-3"
                type="submit"
                size="sm"
                color="success"
                disabled={isReject ? true : false}
              >
                {isReject && (
                  <Spinner
                    style={{ height: "16px", width: "16px" }}
                    className="mr-2"
                  />
                )}
                Refuser
              </Button>
              <Button
                className="font-weight-bold px-3"
                size="sm"
                disabled={isReject ? true : false}
                onClick={() => setisOpenRefuseModal(false)}
              >
                Annuler
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          className="px-3  font-weight-bold"
          color="success"
          disabled={isSave ? true : false}
          onClick={acceptOrder}
        >
          {isSave && <Spinner style={{ height: "16px", width: "16px" }} />}
          {"  "}
          Accepter
        </Button>
        <Button
          size="sm"
          className="px-3 font-weight-bold"
          disabled={isSave ? true : false}
          onClick={() => setisOpenRefuseModal(true)}
        >
          Refuser
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalMobileNewOrderDetails;
