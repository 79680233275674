import React, { useEffect, useRef, useState } from "react";
import Chartjs from "chart.js";
import { Popover, Form, Input, FormGroup, Label } from "reactstrap";
import moment from "moment";
import "./business.scss";

const ChartBusiness = ({ settotalMoney, orders, setnumberOfOrders }) => {
  const chartContainer = useRef(null);
  const [chartInstance, setchartInstance] = useState(null);
  const [activeFilter, setactiveFilter] = useState(0);
  const [popoverOpenXDate, setpopoverOpenXDate] = useState(false);
  const [popoverOpenXYear, setpopoverOpenXYear] = useState(false);

  const togglePopoverXDate = () => {
    setpopoverOpenXDate(!popoverOpenXDate);
    setactiveFilter(4);
    setpopoverOpenXYear(false);
  };

  const togglePopoverXYear = () => {
    setpopoverOpenXYear(!popoverOpenXYear);
    setactiveFilter(5);
    setpopoverOpenXDate(false);
  };

  const chartConfig = () => {
    return {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Vente",
            fill: true,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderCapStyle: "butt",
            borderDash: [10],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [],
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    };
  };
  const updateDataset = (datasetIndex, newData) => {
    chartInstance.data.datasets[datasetIndex].data = newData;
    chartInstance.data.labels = ["Aujourd'hui"];
    chartInstance.update();
  };

  const onToDayClick = () => {
    let newTotal = 0;
    const ordersTarget = orders.filter(
      (order) =>
        moment(order.createAt).format("DD-MM-YYYY") ===
        moment().format("DD-MM-YYYY")
    );
    ordersTarget.forEach((order) => {
      newTotal += order.price;
    });
    const data = [newTotal, 0, newTotal + 1];
    setactiveFilter(1);
    updateDataset(0, data);
    settotalMoney(newTotal);
    setnumberOfOrders(ordersTarget.length);
  };

  const onWeekClick = () => {
    setactiveFilter(2);
    let data = [];
    let labels = [];
    let newTotal = 0;
    let ordersTarget = [];
    const intervalStartDate = new Date();
    const intervalEndDate = new Date();

    if (orders) {
      intervalStartDate.setDate(intervalStartDate.getDate() - 1);
      intervalEndDate.setDate(intervalEndDate.getDate() - 8);
      ordersTarget = orders.filter((order) => {
        return (
          order.createAt < intervalStartDate.getTime() &&
          order.createAt > intervalEndDate.getTime()
        );
      });
      setnumberOfOrders(ordersTarget.length);

      for (let index = 7; index >= 1; index--) {
        const currentDate = new Date();
        let totalPerDay = 0;

        currentDate.setDate(currentDate.getDate() - index);

        switch (currentDate.getDay()) {
          case 0:
            labels.push("Dimanche");
            break;
          case 1:
            labels.push("Lundi");
            break;
          case 2:
            labels.push("Mardi");
            break;
          case 3:
            labels.push("Mercredi");
            break;
          case 4:
            labels.push("Jeudi");
            break;
          case 5:
            labels.push("Vendredi");
            break;
          case 6:
            labels.push("Samedi");
            break;
          default:
        }

        for (
          let orderIndex = 0;
          orderIndex < ordersTarget.length;
          orderIndex++
        ) {
          const orderDate = moment(ordersTarget[orderIndex].createAt).format(
            "DD-MM-YYYY"
          );
          const currentDateInMoment = moment(currentDate).format("DD-MM-YYYY");

          if (currentDateInMoment === orderDate) {
            totalPerDay += ordersTarget[orderIndex].price;
            newTotal += ordersTarget[orderIndex].price;
          }
        }
        data.push(totalPerDay);
      }
    }
    chartInstance.data.labels = labels;
    chartInstance.data.datasets[0].data = data;
    chartInstance.update();
    console.log(labels);
    settotalMoney(newTotal);
  };

  const onMonthClick = () => {
    setactiveFilter(3);
    let data = [];
    let labels = [];
    let newTotal = 0;
    let ordersTarget = [];
    const intervalStartDate = new Date();
    const intervalEndDate = new Date();

    if (orders) {
      intervalStartDate.setDate(intervalStartDate.getDate() - 1);
      intervalEndDate.setDate(intervalEndDate.getDate() - 31);

      ordersTarget = orders.filter((order) => {
        return (
          order.createAt < intervalStartDate.getTime() &&
          order.createAt > intervalEndDate.getTime()
        );
      });
      setnumberOfOrders(ordersTarget.length);
      for (let index = 30; index >= 1; index--) {
        const currentDate = new Date();
        let totalPerDay = 0;
        currentDate.setDate(currentDate.getDate() - index);
        labels.push(moment(currentDate).format("DD/MM"));

        for (
          let orderIndex = 0;
          orderIndex < ordersTarget.length;
          orderIndex++
        ) {
          const orderDate = moment(ordersTarget[orderIndex].createAt).format(
            "DD-MM-YYYY"
          );
          const currentDateInMoment = moment(currentDate).format("DD-MM-YYYY");

          if (currentDateInMoment === orderDate) {
            totalPerDay += ordersTarget[orderIndex].price;
            newTotal += ordersTarget[orderIndex].price;
          }
        }
        data.push(totalPerDay);
      }
      updateDataset(0, data);
      chartInstance.data.labels = labels;
      chartInstance.update();
      settotalMoney(newTotal);
    }
  };

  const onXDayClick = (e) => {
    setactiveFilter(4);
    let newTotal = 0;
    const ordersTarget = orders.filter(
      (order) =>
        moment(order.createAt).format("DD-MM-YYYY") ===
        moment(e.target.value).format("DD-MM-YYYY")
    );
    ordersTarget.forEach((order) => {
      newTotal += order.price;
    });
    chartInstance.data.datasets[0].data = [newTotal, 0, newTotal + 1];
    chartInstance.data.labels = [
      `Le ${moment(e.target.value).format("DD-MM-YYYY")}`,
    ];
    chartInstance.update();
    settotalMoney(newTotal);
    setnumberOfOrders(ordersTarget.length);
    setpopoverOpenXDate(false);
  };

  const onYearClick = (year) => {
    setactiveFilter(5);
    let data = [];
    let labels = [];
    let newTotal = 0;
    let ordersTarget = [];

    if (orders) {
      ordersTarget = orders.filter((order) => {
        return moment(order.createAt).format("YYYY") === year;
      });
      setnumberOfOrders(ordersTarget.length);

      for (let index = 0; index < 12; index++) {
        let totalPerMonth = 0;
        switch (index) {
          case 0:
            labels.push("Janvier");
            break;
          case 1:
            labels.push("Février");
            break;
          case 2:
            labels.push("Mars");
            break;
          case 3:
            labels.push("Avril");
            break;
          case 4:
            labels.push("Mai");
            break;
          case 5:
            labels.push("Juin");
            break;
          case 6:
            labels.push("Juillet");
            break;
          case 7:
            labels.push("Août");
            break;
          case 8:
            labels.push("Septembre");
            break;
          case 9:
            labels.push("Octobre");
            break;
          case 10:
            labels.push("Novembre");
            break;
          case 11:
            labels.push("Décembre");
            break;
          default:
        }

        for (
          let orderIndex = 0;
          orderIndex < ordersTarget.length;
          orderIndex++
        ) {
          const orderMonth = moment(ordersTarget[orderIndex].createAt).format(
            "MM-YYYY"
          );
          const currentMonthInMoment = `0${index + 1}-${year}`;

          if (currentMonthInMoment === orderMonth) {
            totalPerMonth += ordersTarget[orderIndex].price;
            newTotal += ordersTarget[orderIndex].price;
          }
        }
        data.push(totalPerMonth);
      }
    }

    chartInstance.data.labels = labels;
    chartInstance.data.datasets[0].data = data;
    chartInstance.update();
    settotalMoney(newTotal);
    setpopoverOpenXYear(false);
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current && orders) {
      const newChartInstance = new Chartjs(
        chartContainer.current,
        chartConfig()
      );
      setchartInstance(newChartInstance);
    }
    return () => {};
  }, [chartContainer]);

  return (
    <div>
      <div className="days-data-input py-2">
        <h6
          className={`days-data-item mr-3 mb-0 ${
            activeFilter === 1 && "active"
          }`}
          onClick={onToDayClick}
        >
          Aujourd'hui
        </h6>
        <h6
          className={`days-data-item mr-3 mb-0 ${
            activeFilter === 2 && "active"
          }`}
          onClick={onWeekClick}
        >
          7 jours
        </h6>
        <h6
          className={`days-data-item mr-3 mb-0 ${
            activeFilter === 3 && "active"
          }`}
          onClick={onMonthClick}
        >
          30 jours
        </h6>
        <h6
          id="x-date"
          className={`days-data-item mb-0 mr-3 ${
            activeFilter === 4 && "active"
          }`}
        >
          X date
        </h6>
        <h6
          id="x-year"
          className={`days-data-item mb-0 ${activeFilter === 5 && "active"}`}
        >
          Année
        </h6>
        <Popover
          popperClassName="popover-x-date px-2"
          placement="bottom"
          isOpen={popoverOpenXDate}
          target="x-date"
          toggle={togglePopoverXDate}
        >
          <FormGroup>
            <Label size="sm" className="mb-0 font-weight-bold">
              Date
            </Label>
            <Input
              type="date"
              name="xDate"
              bsSize="sm"
              onChange={onXDayClick}
            />
          </FormGroup>
        </Popover>
        <Popover
          popperClassName="popover-x-year"
          placement="bottom"
          isOpen={popoverOpenXYear}
          target="x-year"
          toggle={togglePopoverXYear}
        >
          <h6
            onClick={onYearClick.bind(this, "2020")}
            className="year-filter px-2 mb-0"
          >
            2020
          </h6>
        </Popover>
      </div>
      <div className="col-chart">
        <canvas ref={chartContainer} />
      </div>
    </div>
  );
};

export default ChartBusiness;
