import React, { useState, useEffect } from "react";
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Spinner, Form, Input, Label, Button, Row, Col, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useForm } from "react-hook-form";
import Toast from "../toast/toast";

const RestaurantInfo = () => {
  const [restaurant, setrestaurant] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [isSave, setisSave] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setisError] = useState(false)
  const { register, handleSubmit } = useForm();
  const db = useFirestore();

  useFirestoreConnect(() => [
    {
      collection: "restaurants"
    }
  ]);

  const restaurants = useSelector(state => state.firestore.ordered.restaurants);

  const toggleEdit = () => {
    setisEdit(true);
  };

  const toggleToastSuccess = () => {
    setisSuccess(!isSuccess);
  };

  const toggleToastError = () => {
      setisError(!isError)
  }

  const onSubmit = data => {
    setisSave(true);
    setisEdit(false);
    db.update(`restaurants/${restaurant.id}`, {
      ...data
    })
      .then(res => {
        setisSave(false);
        toggleToastSuccess();
      })
      .catch(err => {

        setisEdit(true);
        setisSave(false);
        toggleToastError()
      });
  };
  useEffect(() => {
    restaurants &&
      setrestaurant(
        restaurants.find(res => res.id === localStorage.getItem("restaurantID"))
      );
    return () => {};
  }, [restaurants]);

  return (
    <div className="restoInfo">
      {!isLoaded(restaurants) ? (
        <div className="loading-resto-incator">
          <Spinner
            type="grow"
            style={{ height: "32px", width: "32px", backgroundColor: "orange" }}
          />
        </div>
      ) : (
        <div>
          <div
            style={{
              backgroundImage: `url(${restaurant && restaurant.banner})`,
              backgroundRepeat: "none",
              backgroundSize: "cover",
              height: "200px"
            }}
          />
          <div className="div-edit p-1">
            <p className="mb-0">
              Enregistré sur Tayarifood le{" "}
              {restaurant && moment(restaurant.createAt).format("DD/MM/YYYY à HH:mm")}
            </p>
            <Button size="sm" onClick={toggleEdit}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </div>
          <div className="px-md-5 px-1 pb-3 pt-2">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Label className="mb-0 font-weight-bold">Nom du restaurant</Label>
              <Input
                type="text"
                defaultValue={restaurant && restaurant.name}
                bsSize="sm"
                disabled={isEdit ? false : true}
                name="name"
                innerRef={register({ required: true })}
              />
              <Label className="mb-0 font-weight-bold">Ville</Label>
              <Input
                type="text"
                defaultValue={restaurant && restaurant.city}
                bsSize="sm"
                disabled
              />

              <Label className="mb-0 font-weight-bold">Adresse locale</Label>
              <Input
                type="textarea"
                defaultValue={restaurant && restaurant.adresse}
                bsSize="sm"
                disabled={isEdit ? false : true}
                name="adresse"
                innerRef={register({ required: true })}
              />

              <Label className="mb-0 font-weight-bold">Propriétaire</Label>
              <Input
                type="text"
                defaultValue={restaurant && restaurant.owner}
                bsSize="sm"
                disabled
              />

              <Label className="mb-0 font-weight-bold">Téléphone</Label>
              <Input
                type="text"
                defaultValue={restaurant && restaurant.phone}
                bsSize="sm"
                disabled
              />
              <Label className="mb-0 font-weight-bold">Email</Label>
              <Input
                type="text"
                defaultValue={restaurant && restaurant.email}
                bsSize="sm"
                disabled
              />

              <Label className="mb-0 font-weight-bold">Slogan</Label>
              <Input
                type="text"
                defaultValue={restaurant && restaurant.slogan}
                bsSize="sm"
                disabled={isEdit ? false : true}
                name="slogan"
                innerRef={register}
              />
              <Label className="mb-0 font-weight-bold">
                Description du restaurant
              </Label>
              <Input
                type="textarea"
                rows="6"
                defaultValue={restaurant && restaurant.detail}
                bsSize="sm"
                disabled={isEdit ? false : true}
                name="detail"
                innerRef={register}
              />
               <Label className="font-weight-bold">
            Numéros de réception de payements
          </Label>
          <div className="information-payement-div mb-2 px-2">
            <Row form>
              <Col>
                <FormGroup>
                  <Label>
                    Airtel money{" "}
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="text-success"
                    />
                  </Label>
                  <Input
                    type="text"
                    name="payementNumberAirtel"
                    disabled={true}
                    defaultValue={restaurant && restaurant.payementNumberAirtel}
                    innerRef={register({ required: true })}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    M-pesa{" "}
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="text-success"
                    />
                  </Label>
                  <Input
                    type="text"
                    name="payementNumberMpesa"
                    defaultValue={restaurant && restaurant.payementNumberMpesa}
                    disabled={true}
                    innerRef={register({ required: true })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

              <div className="text-right pt-2">
                {isEdit ? (
                  <Button
                    type="submit"
                    color="success"
                    size="sm"
                    className="px-3 font-weight-bold"
                    disabled={isSave ? true : false}
                  >
                    {isSave && (
                      <Spinner style={{ height: "16px", width: "16px" }} />
                    )}{" "}
                    Mettre à jour
                  </Button>
                ) : isSave ? (
                  <Button
                    type="submit"
                    color="success"
                    size="sm"
                    className="px-3 font-weight-bold"
                    disabled={isSave ? true : false}
                  >
                    {isSave && (
                      <Spinner style={{ height: "16px", width: "16px" }} />
                    )}{" "}
                    Mettre à jour
                  </Button>
                ) : (
                  undefined
                )}
              </div>
            </Form>
          </div>
        </div>
      )}
      <Toast
        className="bg-success text-white"
        isShow={isSuccess}
        toggle={toggleToastSuccess}
        message="Mise en jour"
        delay={4000}
        icon="check"
      />
      <Toast
        className="bg-danger text-white"
        isShow={isError}
        toggle={toggleToastError}
        message="Erreur"
        delay={4000}
        icon=""
      />
    </div>
  );
};

export default RestaurantInfo;
