import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Button,
  Input,
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faSearch,
  faChevronRight,
  faLayerGroup
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import "./menu.scss";
import NewDishForm from "./newDishForm";
import ToastMessage from "../toast/toast";
import ListDishes from "./listDishes";
import LoadingData from "./loadingData";
import { Animated } from "react-animated-css";
import {useMediaQuery} from "react-responsive"

const Menu = () => {
  const [currentCategoryDish, setcurrentCategoryDish] = useState("");
  const [currentListDishes, setcurrentListDishes] = useState([]);
  const [isOpenNewDish, setisOpenNewDish] = useState(false);
  const [isOpenEditDish, setisOpenEditDish] = useState(false);
  const [dishToEdit, setdishToEdit] = useState();
  const [isToastSuccess, setisToastSuccess] = useState(false);
  const [isToastDanger, setisToastDanger] = useState(false);
  const [isToastDelete, setisToastDelete] = useState(false);
  const [statusFilterLabel, setstatusFilterLabel] = useState("Tous");
  const isSmallScreen = useMediaQuery({ query: "(max-width:768.99px)" });

  useFirestoreConnect(() => [
    { collection: "categories" },
    { collection: "galleryDishes" },
    {
      collection: "restaurants",
      doc: localStorage.getItem("restaurantID"),
      subcollections: [{ collection: "menu" }],
      storeAs: "menu"
    }
  ]);
  const categories = useSelector(state => state.firestore.ordered.categories);
  const menu = useSelector(state => state.firestore.ordered.menu);
  const galleryDishes = useSelector(
    state => state.firestore.ordered.galleryDishes
  );

  const toggleNewDish = () => {
    setisOpenNewDish(true);
  };

  const toggleEditDish = dish => {
    setisOpenNewDish(true);
    setisOpenEditDish(true);
    setdishToEdit(dish);
  };

  const cancelDishForm = () => {
    setisOpenNewDish(false);
    setisOpenEditDish(false);
  };

  const toggleToastSuccess = () => {
    setisToastSuccess(!isToastSuccess);
  };

  const toggleToastDanger = () => {
    setisToastDanger(!isToastDanger);
  };
  const toggleToastDelete = () => {
    setisToastDelete(!isToastDelete);
  };

  const viewCurrentDishesByCategory = categoryName => {
    const currentNewList = menu.filter(dish =>
      dish.categories.includes(categoryName)
    );
    setcurrentListDishes(currentNewList);
    setcurrentCategoryDish(categoryName);
  };

  const viewAllDishes = () => {
    setcurrentListDishes(menu);
    setcurrentCategoryDish();
    setstatusFilterLabel("Tous");
  };

  const viewAllDishesByStatus = (status, label) => {
    setcurrentListDishes(menu.filter(dish => dish.status === status));
    setstatusFilterLabel(label);
  };

  const viewUncategorizedDish = () => {
    setcurrentListDishes(menu.filter(dish => dish.categories.length === 0));
    setcurrentCategoryDish("uncategorized");
  };

  const onSearch = e => {
    const searchString = RegExp(e.target.value, "i");
    const newCurrentListDish = menu.filter(
      dish =>
        dish.name.match(searchString) || (dish.price + "").match(searchString)
    );
    setcurrentListDishes(newCurrentListDish);
    setcurrentCategoryDish("");
    // setstatusFilterLabel("Tous");
  };

  useEffect(() => {
    menu && setcurrentListDishes(menu);
    return () => {};
  }, [menu]);

  return (
    <Container className="menu-container" fluid>
      <div className="container-header mb-3">
        <h4 className="text-left font-weight-normal container-title">
          <Badge className="text-white number-food px-2 mr-1">
            {menu && menu.length}
          </Badge>
          Repas au menu
        </h4>
        {menu && (
          <p className="my-0" onClick={toggleNewDish}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Ajouter
          </p>
        )}
      </div>
      {!isLoaded(menu) ? (
        <LoadingData />
      ) : (
        <Row>
          <div className="col-xs-12 col-sm-4 col-md-3 category-menu">
            {!isSmallScreen ? <ListGroup >
              <ListGroupItem className="Categorie-menu-item-header font-weight-bold ">
                <FontAwesomeIcon icon={faLayerGroup}/> Categories
              </ListGroupItem>
              <div className="col-categories px-1 pt-1">
              <Animated animationIn="slideInLeft">
                <ListGroupItem
                  className={`category-menu-item mb-1 ${
                    !currentCategoryDish ? "active-menu" : undefined
                  }`}
                  onClick={isOpenEditDish || isOpenNewDish ? undefined: viewAllDishes }
                >
                  <div className="">
                    <p className="my-0">Toutes ({menu && menu.length})</p>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="icon-active-category"
                    />
                  </div>
                </ListGroupItem>
              </Animated>
              <Animated animationIn="slideInLeft">
                <ListGroupItem
                  className={`category-menu-item mb-1 ${
                    currentCategoryDish === "uncategorized"
                      ? "active-menu"
                      : undefined
                  }`}
                  onClick={isOpenEditDish || isOpenNewDish ? undefined:viewUncategorizedDish}
                >
                  <div className="">
                    <p className="my-0">
                      Non categorisé (
                      {menu &&
                        menu.filter(dish => dish.categories.length === 0)
                          .length}
                      )
                    </p>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="icon-active-category"
                    />
                  </div>
                </ListGroupItem>
              </Animated>
              <div className="fixed-col-category overflow-auto">
                {categories &&
                  categories.map(category => (
                    <Animated animationIn="slideInLeft" key={category.id}>
                      <ListGroupItem
                        className={`category-menu-item mb-1 ${
                          currentCategoryDish === category.category
                            ? "active-menu"
                            : undefined
                        }`}
                        onClick={isOpenEditDish || isOpenNewDish ? undefined: viewCurrentDishesByCategory.bind(
                          this,
                          category.category
                        )}
                      >
                        <div className="">
                          <p className="my-0">
                            {category.category} (
                            {menu &&
                              menu.filter(dish =>
                                dish.categories.includes(category.category)
                              ).length}
                            )
                          </p>
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="icon-active-category"
                          />
                        </div>
                      </ListGroupItem>
                    </Animated>
                  ))}
              </div>
              </div>
            </ListGroup>:<div></div>}
            
          </div>
          <div className="col-sm-12 col-md-9 col-dishes">
            {!isOpenNewDish ? (
              <div>
                <div className="search-bar-menu p-2">
                  {/* <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="font-weight-bold px-3"
                      size="sm"
                    >
                      {statusFilterLabel}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>Tous</DropdownItem>
                      <DropdownItem
                        onClick={viewAllDishesByStatus.bind(
                          this,
                          true,
                          "Disponible"
                        )}
                      >
                        Disponible
                      </DropdownItem>
                      <DropdownItem
                        onClick={viewAllDishesByStatus.bind(
                          this,
                          false,
                          "Non disponible"
                        )}
                      >
                        Non disponible
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  <Input
                    type="text"
                    bsSize="sm"
                    placeholder="Rechercher ..."
                    onChange={onSearch}
                  />
                  <Button size="sm">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </div>
                <ListDishes
                  menu={currentListDishes}
                  toggleEditDish={toggleEditDish}
                  toggleToastDelete={toggleToastDelete}
                />
              </div>
            ) : (
              <NewDishForm
                categories={categories}
                toggle={cancelDishForm}
                toggleToastSuccess={toggleToastSuccess}
                toggleToastDanger={toggleToastDanger}
                gallery={galleryDishes}
                isOpenEditDish={isOpenEditDish}
                dishToEdit={dishToEdit}
              />
            )}
          </div>
          <ToastMessage
            isShow={isToastSuccess}
            toggle={toggleToastSuccess}
            icon="check"
            delay={4000}
            message="Enregistré"
            className="bg-success text-white"
          />
          <ToastMessage
            isShow={isToastDelete}
            toggle={toggleToastDelete}
            icon="check"
            delay={4000}
            message="Supprimé"
            className="bg-success text-white"
          />
          <ToastMessage
            isShow={isToastDanger}
            toggle={toggleToastDanger}
            icon="times"
            delay={4000}
            message="Échec"
            className="bg-danger text-white"
          />
        </Row>
      )}
    </Container>
  );
};
export default Menu;
