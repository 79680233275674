import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Badge,
  Alert,
  Button,
  Container,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faInfoCircle,
  faHistory,
  faBicycle,
} from "@fortawesome/free-solid-svg-icons";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ListNewOrders from "./listNewOrders";
import NewOrderDetails from "./NewOrderDetails";
import "./home.scss";
import ListTodeliverOrders from "./listTodeliverOrders";
import DeliverOrderDetails from "./deliverOrderDetails";
import PreparingOrdersDetails from "./preparingOrdersDetails";
import ListPreparingOders from "./listPreparingOders";
import LoadingData from "../menu/loadingData";
import Riders from "./riders";
import NoSelectedOrder from "./noSelectedOrder";
import ModalMobileNewOrderDetails from "./modalMobileNewOrderDetails";
import ModalMobileDeliverOrderDetails from "./modalMobileDeliverOrderDetails";
import ModalMobilePreparingOrderDetails from "./modalMobilePreparingOrderDetails";

const Home = ({ history }) => {
  const [activeTab, setActiveTab] = useState("1");
  useFirestoreConnect(() => [
    {
      collection: "restaurants",
      doc: localStorage.getItem("restaurantID"),
      subcollections: [{ collection: "orders" }],
      storeAs: "orders",
    },
    {
      collection: "riders",
    },
  ]);
  const orders = useSelector((state) => state.firestore.ordered.orders);
  const riders = useSelector((state) => state.firestore.ordered.riders);
  const [ridersOfCity, setridersOfCity] = useState([]);
  const [newOrders, setnewOrders] = useState([]);
  const [prepareOrders, setprepareOrders] = useState([]);
  const [deliveOrders, setdeliveOrders] = useState([]);
  const [currentNewOrder, setcurrentNewOrder] = useState({});
  const [currentPrepareOrder, setcurrentPrepareOrder] = useState({});
  const [currentDeliveOrder, setcurrentDeliveOrder] = useState({});
  const [activeNewOrderId, setactiveNewOrderId] = useState();
  const [activePrepareOrderId, setactivePrepareOrderId] = useState();
  const [activeDeliveOrderId, setactiveDeliveOrderId] = useState();
  const [isOpenRidersModal, setisOpenRidersModal] = useState(false);
  const [riderOrder, setriderOrder] = useState();
  const [isOpenModalNewOrderDetails, setisOpenModalNewOrderDetails] = useState(
    false
  );
  const [
    isOpenModalDeliverOrderDetails,
    setisOpenModalDeliverOrderDetails,
  ] = useState(false);
  const [
    isOpenModalPreparingOrderDetails,
    setisOpenModalPreparingOrderDetails,
  ] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width:768.99px)" });
const isExtraSmallScreen = useMediaQuery({query:"(max-width:575.99px)"})
  const toggleCurrentTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleRidersModal = () => {
    setisOpenRidersModal(!isOpenRidersModal);
  };

  const toggleDetailNewOrder = (order) => {
    setcurrentNewOrder(order);
    setactiveNewOrderId(order.id);
    setisOpenModalNewOrderDetails(true);
  };

  const toggleDetailPreparedOrder = (order) => {
    setcurrentPrepareOrder(order);
    setactivePrepareOrderId(order.id);
    setisOpenModalPreparingOrderDetails(true)
  };

  const toggleDetailDeliveredOrder = (order) => {
    setcurrentDeliveOrder(order);
    setactiveDeliveOrderId(order.id);
    setriderOrder(ridersOfCity.find((rider) => rider.id === order.riderId));
    setisOpenModalDeliverOrderDetails(true);
  };

  const resetActiveNewOrderDetails = () => {
    setactiveNewOrderId();
  };

  const resetActivePrepareOrderDetails = () => {
    setactivePrepareOrderId();
  };

  const goToHistoryOrders = () => {
    history.push("/d/historique");
  };

  useEffect(() => {
    if (orders) {
      setnewOrders(orders.filter((order) => order.status === "sent"));
      setprepareOrders(orders.filter((order) => order.status === "accepted"));
      setdeliveOrders(orders.filter((order) => order.status === "delegated"));
      riders &&
        setridersOfCity(
          riders.filter((rider) => rider.city === localStorage.getItem("city") && rider.status==="online")
        );
      setactiveDeliveOrderId();
    }
    return () => {};
  }, [orders, riders]);

  return (
    <div className="container-home px-2 py-2">
      {!isLoaded(orders) ? (
        <LoadingData />
      ) : (
        <div className="">
          <Nav className="home-nav pr-1">
            <NavItem className="tab">
              <NavLink
                className={classnames("link-tab", {
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleCurrentTab("1");
                }}
              >
                {!isExtraSmallScreen?"NOUVELLES":"NOUV."}
                <span className="xxx"> COMMANDES</span>{" "}
                <Badge style={{fontSize:"0.6rem"}} id="nb-new" color="warning">
                  {newOrders.length}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem className="tab">
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleCurrentTab("2");
                }}
              >
                
                {!isExtraSmallScreen ? "EN COURS":"PREP."}
                <span className="xxx"> DE PREPARATION</span>{" "}
                <Badge style={{fontSize:"0.6rem"}} id="nb-preparing" color="warning">
                  {prepareOrders.length}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem className="tab">
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleCurrentTab("3");
                }}
              >
                <span className="xxx"> PRISE</span> {!isExtraSmallScreen?"EN CHARGE ":"CHAR."}
                <Badge style={{fontSize:"0.6rem"}} id="nb-ride" color="warning">
                  {deliveOrders.length}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <Button
                outline
                className="ml-1"
                size="sm"
                onClick={goToHistoryOrders}
              >
                <FontAwesomeIcon icon={faHistory} />
              </Button>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="">
            <TabPane tabId="1" className="container-fluid">
              <Row className="tab-row">
                <div className="col-md-4 px-0">
                  <div className="col-tab-fixed-height p-1 overflow-auto">
                    {newOrders.length !== 0 ? (
                      <ListNewOrders
                        orders={newOrders}
                        showDetails={toggleDetailNewOrder}
                        activeId={activeNewOrderId}
                      />
                    ) : (
                      <Alert color="danger" className="empty-list-order mt-2">
                        <FontAwesomeIcon icon={faInfoCircle} /> Pas de nouvelle
                        (s) commandes
                      </Alert>
                    )}
                    {isSmallScreen && activeNewOrderId ? (
                      <ModalMobileNewOrderDetails
                        isOpen={isOpenModalNewOrderDetails}
                        setisOpenModalNewOrderDetails={
                          setisOpenModalNewOrderDetails
                        }
                        order={currentNewOrder}
                        resetActiveId={resetActiveNewOrderDetails}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-md-8 col-details-new-order pr-0">
                  {activeNewOrderId ? (
                    <NewOrderDetails
                      order={currentNewOrder}
                      resetActiveId={resetActiveNewOrderDetails}
                    />
                  ) : (
                    <NoSelectedOrder />
                  )}
                </div>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="container-fluid">
              <Row className="tab-row">
                <div className="col-md-4 px-0">
                  <div className="col-tab-fixed-height p-1 overflow-auto">
                    {prepareOrders.length !== 0 ? (
                      <ListPreparingOders
                        orders={prepareOrders}
                        showDetails={toggleDetailPreparedOrder}
                        activeId={activePrepareOrderId}
                      />
                    ) : (
                      <Alert color="warning empty-list-order  mt-2">
                        <FontAwesomeIcon icon={faInfoCircle} /> Pas de commandes
                        en cours de préparation
                      </Alert>
                    )}
                    {isSmallScreen && activePrepareOrderId ? (
                      <ModalMobilePreparingOrderDetails
                        isOpen={isOpenModalPreparingOrderDetails}
                        setisOpenModalPreparingOrderDetails={
                          setisOpenModalPreparingOrderDetails
                        }
                        order={currentPrepareOrder}
                        toggleRiders={toggleRidersModal}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-md-8 col-details-new-order pr-0">
                  {activePrepareOrderId ? (
                    <PreparingOrdersDetails
                      order={currentPrepareOrder}
                      toggle={toggleRidersModal}
                    />
                  ) : (
                    <NoSelectedOrder />
                  )}
                </div>
              </Row>
            </TabPane>
            <TabPane tabId="3" className="container-fluid">
              <Row className="tab-row">
                <div className="col-md-4 px-0">
                  <div className="col-tab-fixed-height p-1 overflow-auto">
                    {deliveOrders.length !== 0 ? (
                      <ListTodeliverOrders
                        orders={deliveOrders}
                        showDetails={toggleDetailDeliveredOrder}
                        activeId={activeDeliveOrderId}
                      />
                    ) : (
                      <Alert color="success" className="empty-list-order mt-2">
                        <FontAwesomeIcon icon={faInfoCircle} /> Pas de commandes
                        en cours de livraison
                      </Alert>
                    )}
                    {isSmallScreen && activeDeliveOrderId ? (
                      <ModalMobileDeliverOrderDetails
                        isOpen={isOpenModalDeliverOrderDetails}
                        setisOpenModalDeliverOrderDetails={
                          setisOpenModalDeliverOrderDetails
                        }
                        order={currentDeliveOrder}
                        rider={riderOrder}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-md-8 col-details-new-order pr-0">
                  {activeDeliveOrderId ? (
                    <DeliverOrderDetails
                      order={currentDeliveOrder}
                      rider={riderOrder}
                    />
                  ) : (
                    <NoSelectedOrder />
                  )}
                </div>
              </Row>
            </TabPane>
          </TabContent>
          <Riders
            isOpen={isOpenRidersModal}
            toggle={toggleRidersModal}
            riders={ridersOfCity}
            order={currentPrepareOrder}
            orderId={activePrepareOrderId}
            resetActiveId={resetActivePrepareOrderDetails}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
