import React from "react";
import { Toast, ToastBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const ToastMessage = ({ className, isShow, toggle, message, delay, icon }) => {
  isShow &&
    setTimeout(() => {
      toggle();
    }, delay);

  return (
    isShow && (
      <Toast
        className={`${className}`}
        style={{
          fontSize: "1.2rem",
          position: "fixed",
          right: "3px",
          bottom: "3px",
          zIndex: "500"
        }}
      >
        <ToastBody className="py-1">
          <FontAwesomeIcon
            icon={icon === "check" ? faCheck : faTimes}
            className=" mr-2"
          />
          {message}
        </ToastBody>
      </Toast>
    )
  );
};

export default ToastMessage;
