import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faBicycle, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {Animated} from 'react-animated-css'

function DetailsOrder({ order }) {
  const [foods, setfoods] = useState([]);
  const [totalPrice, settotalPrice] = useState();
  const [rider, setrider] = useState();

  useFirestoreConnect(() => [
    {
      collection: "riders"
    }
  ]);
  const riders = useSelector(state => state.firestore.ordered.riders);

  useEffect(() => {
    let newFoods = [];
    let newTotal = 0;
    order.package.forEach(food => {
      newFoods.push(JSON.parse(food));
      newTotal += JSON.parse(food).food.price * JSON.parse(food).count;
    });
    setfoods(newFoods);
    settotalPrice(newTotal);
    riders && setrider(riders.find(rider => rider.id === order.rider));
    return () => {};
  }, [order, riders]);
  
  return (
    <div>
      <Animated
      animationIn="fadeIn"
      animationInDelay={100}
      animationInDuration={2000}
      animationOut="fadeOut"
      >
        <div className="details-order mt-1  px-3 py-3">
          <ListGroup flush className="px-md-4">
            {foods.map(food => (
              <ListGroupItem
                key={food.food.foodId}
                className="py-0 bg-transparent  order-details-item"
              >
                <p className="order-details-item-quantity my-0 text-right font-weight-bold">
                  1x{food.count}
                </p>
                <p className="text-muted order-details-item-name my-0 ml-2">
                  {food.food.name}
                </p>
                <p className="text-right my-0 ">
                  {food.food.price * food.count} <span className="font-weight-bold">Fc</span>
                </p>
              </ListGroupItem>
            ))}
          </ListGroup>
          <div className="order-details-total pt-1 px-3 mx-md-4">
            <h6 className="mr-1">Total:</h6>
            <h5 className="oder-details-total-value text-right">
              {totalPrice}<span className="font-weight-bold"> Fc</span>
            </h5>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="">
                <h6><FontAwesomeIcon icon={faMapMarkerAlt} /> <span className="text-success">Adresse de livraison</span></h6>
                <div className="bg-white py-1 ml-3 pl-3">
                  <p className="text-muted">
                    {order.destination}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              {rider ? (
                <div className="mt-2 ">
                  <h6 ><FontAwesomeIcon icon={faBicycle}/> <span className="text-success">Livreur</span></h6>
                  <div className="bg-white py-1 ml-2 pl-2 d-flex">
                    <img
                      src={rider.avatar}
                      height="48px"
                      width="48px"
                      className="rounded-circle"
                    />
                    <div className="ml-2">
                      <p className="my-0 font-weight-bold">{rider.name}</p>
                      <p className="my-0 text-muted rider-phone">
                        <FontAwesomeIcon icon={faPhoneAlt} /> {rider.phone}
                      </p>
                    </div>
                  </div>
                </div>
              ):<div className="col-md-6 col-sm-12">
              <div className="">
                <h6><FontAwesomeIcon icon={faStickyNote}/> <span className="text-success">Raison du refus</span></h6>
                <div className="bg-white py-1 ml-3 pl-3">
                  <p className="text-muted">
                    {order.rejectReason}
                  </p>
                </div>
              </div>
            </div>}
            </div>
          </div>
        </div>
      </Animated>
      
    </div>
  );
}


export default DetailsOrder;
