import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Switch, Route } from "react-router-dom";
import NavBar from "./navBar/navBar";
import SideBar from "./sideBar/sideBar";
import BackDrop from "./backDrop/backDrop";
import Home from "./home/home";
import Menu from "./menu/menu";
import Historique from "./historique/historique";
import Business from "./business/business";
import Settings from "./settings/settings";
import Contact from "./contact/contact";
import Aide from "./aide/aide";

const Main = props => {
  const [viewSidebar, setViewSidebar] = useState(false);
  const [restaurant, setrestaurant] = useState({});
  useFirestoreConnect(() => [{ collection: "restaurants" }]);
  const restaurants = useSelector(state => state.firestore.ordered.restaurants);
  const toggleSidebar = () => {
    setViewSidebar(!viewSidebar);
  };

  useEffect(() => {
    restaurants &&
      setrestaurant(
        restaurants.find(
          restaurant => restaurant.id === localStorage.getItem("restaurantID")
        )
      );
    return () => {};
  }, [restaurants]);
  return (
    <div className="main">
      <NavBar toggleSidebar={toggleSidebar} restaurant={restaurant} />
      {viewSidebar ? <BackDrop toggleSidebar={toggleSidebar} /> : null}
      <SideBar show={viewSidebar} setViewSidebar={setViewSidebar} restaurant={restaurant} />
      <main className="main-container">
        <Switch>
          <Route exact path="/d" component={Home} />
          <Route path="/d/menu" component={Menu} />
          <Route path="/d/historique" component={Historique} />
          <Route path="/d/business" component={Business} />
          <Route path="/d/settings" component={Settings} />
          <Route path="/d/contact" component={Contact} />
          <Route path="/d/aide" component={Aide} />
        </Switch>
      </main>
    </div>
  );
};

export default Main;
