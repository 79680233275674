import React from 'react'
import { Button } from 'reactstrap'
import {ReactComponent as SuccessImage} from "../../success.svg"
import { Link } from 'react-router-dom'

function Success() {
    return (
        <div className="success-password">
            <SuccessImage style={{height:"12%", width:"15%"}}/>
            <p className="mb-0 mt-2">Votre mot de passe a été modifier avec succès!</p>
            <Link to="/"><Button size="sm" color="warning" className="font-weight-bold mt-2 px-3">Connectez vous ici</Button></Link>
        </div>
    )
}

export default Success
