import React, {useState} from "react";
import { Container, Form, Input, Label, FormGroup, Button, Spinner, Alert } from "reactstrap";
import {useForm} from "react-hook-form";
import { useFirebase, useFirestore} from 'react-redux-firebase'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import "./login.scss";
import CopyRight from "../copyRight/copyRight";
import ToastMessage from "../toast/toast"
import { Link } from "react-router-dom";

const Login = props => {
  const { register, handleSubmit, errors } = useForm();
  const [isLogin, setisLogin] = useState(false);
  const [errorMessage, seterrorMessage] = useState("Ouf, une erreur est survenue! Veillez ressayer")
  const [showDanger, setshowDanger] = useState(false)
  const firebase = useFirebase()
  const db = useFirestore()

  const handleShowDanger = () => {
    setshowDanger(!showDanger)
  }
  const onSubmit = data => {
    setisLogin(true);
    firebase
      .login(data)
      .then(res => {
        const uid = res.user.user.uid
        db
          .collection("restaurants")
          .where("uid", "==", `${uid}`)
          .get()
          .then(res => {
            if(!res.empty){
              setisLogin(false);
              localStorage.setItem("restaurantID", `${res.docs[0].id}`)
              localStorage.setItem("city",`${res.docs[0].data().city}`)
              res.docs[0].data().status!="bloqued" ? props.history.push("/d/"): props.history.push("/error");
            }else{
              setisLogin(false);
              handleShowDanger()
          }
          })
      })
      .catch(err => {
        setisLogin(false);
        switch (err.code) {
          case "auth/user-not-found":
            seterrorMessage("Adresse mail non reconnu par tayarifood");
            break;
          case "auth/network-request-failed":
            seterrorMessage("Vérifier votre connexion internet");
            break;
          case "auth/wrong-password":
            seterrorMessage("Mot de passe incorrecte")
            break
          default:
            break;
        }
        handleShowDanger()
        console.log(err.code)
      });
  };

  return (
    <React.Fragment>
    <Container className="container-login" fluid>
      <div className="row" style={{backgroundImage:`url(${"/assets/images/bg-home.webp"})`, backgroundRepeat:"none", backgroundSize:"cover"}}>
        <div className="container-login-form col-sm-12 col-md-5 col-lg-4">
          <img
            src="/assets/images/logo.png"
            alt=""
            width="180px"
            className="mt-2"
          />
          <h3 className="mt-5 mb-4 font-weight-normal text-muted">Connexion</h3>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="px-sm-0 px-md-3 px-lg-5"
          >
            <FormGroup className="mb-2">
              <Label size="md" className="font-weight-bold mb-0">
                Email
              </Label>
              <Input
                name="email"
                disabled={isLogin ? true : false}
                innerRef={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
              />
            </FormGroup>
            <FormGroup className="">
              <Label className="font-weight-bold mb-0">Mot de passe</Label>
              <Input
                name="password"
                type="password"
                disabled={isLogin ? true :false}
                innerRef={register({
                  required: true,
                  minLength: 6,
                  maxLength: 14
                })}
              />
            </FormGroup>
            <div className="text-center">
              <Button
                className="btn-connexion font-weight-bold px-4"
                type="submit"
                disabled={isLogin ? true : false}
              >
                {!isLogin ? "Connexion" : <Spinner color="light" style={{height:"16px", width:"16px"}}/>}
              </Button>
            </div>
            <Link to="/password-forgotten">
              <p className="text-center password-forgot mt-2">
                Mot de passe oublié?
              </p>
            </Link>
          </Form>
          <Alert color="warning">
                <FontAwesomeIcon icon={faInfoCircle} className="text-warning"/>{" "}
                Si vous n'avez pas de compte tayarifood, veillez enregistrer votre restaurant dans un des nos bureaux de votre milieu. 
                </Alert>
          <CopyRight color="rgba(0,0,0,0.5)" />
        </div>
        <div
          className="col-sm-12 col-md-7 col-lg-8 login-img-bck">
           
          </div>
      </div>
    </Container>

    <ToastMessage
    message={errorMessage}
    icon="times"
    delay={5000}
    toggle={handleShowDanger}
    isShow={showDanger}
    className="bg-danger text-white"
    />
    </React.Fragment>
  );
};
export default Login;
