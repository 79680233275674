import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem, Badge, CustomInput } from "reactstrap";
import "./home.scss";
import moment from "moment";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import TimerOrder from "./timerOrder";
import UIfx from "uifx";
import fileSound from "../../sounds/beep.mp3";

const ListNewOrders = ({ orders, showDetails, activeId }) => {
  const [oldLength, setoldLength] = useState(0);
  const notification = new UIfx(fileSound);
  const [paidOrders, setpaidOrders] = useState([]);
  const [isPaidFilter, setpaidFilter] = useState(false);

  const handleFilter = () => {
    setpaidFilter(!isPaidFilter);
  };

  useEffect(() => {
    if (orders) {
      if (orders.length > oldLength) {
        notification.play();
      }
      setpaidOrders(
        orders.filter((order) => (order.paymentStatus === "success"))
      );
      setTimeout(() => {
        setoldLength(orders.length);
      }, 100);
    }
    return () => {};
  }, [orders]);

  console.log(isPaidFilter);
  return (
    <ListGroup className="list-new-orders">
      <div className="px-1">
        <CustomInput
          type="switch"
          id="status"
          name="status"
          className="text-success"
          label="Voir commandes payées uniquement"
          checked={isPaidFilter ? true : false}
          onChange={handleFilter}
        />
      </div>
      {!isPaidFilter
        ? orders.map((order) => (
            <Animated
              animationIn="slideInLeft"
              animationInDelay={200}
              key={order.id}
              animationOut="slideOutLeft"
            >
              <ListGroupItem
                className={`order-item mb-1 ${
                  order.id === activeId ? "active-item" : null
                }`}
                onClick={showDetails.bind(this, order)}
              >
                <div className="order-info">
                  <p className="my-0 font-weight-bold">
                    #{order.orderNumber}{" "}
                    {order.paymentStatus === "success" ? (
                      <Badge color="success" className="font-weight-normal">
                        Payée
                      </Badge>
                    ) : (
                      <Badge color="danger" className="font-weight-normal">
                        Non payée
                      </Badge>
                    )}
                  </p>
                  <p className="my-0 client-name">
                    {order.clientName &&
                      order.clientName.split(" ")[
                        order.clientName.split(" ").length - 1
                      ]}{" "}
                    | <FontAwesomeIcon icon={faClock} />{" "}
                    {moment(order.createAt).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
                <TimerOrder createAt={order.createAt} />
              </ListGroupItem>
            </Animated>
          ))
        : paidOrders.map((order) => (
            <Animated
              animationIn="slideInLeft"
              animationInDelay={200}
              key={order.id}
              animationOut="slideOutLeft"
            >
              <ListGroupItem
                className={`order-item mb-1 ${
                  order.id === activeId ? "active-item" : null
                }`}
                onClick={showDetails.bind(this, order)}
              >
                <div className="order-info">
                  <p className="my-0 font-weight-bold">
                    #{order.orderNumber}{" "}
                    {order.paymentStatus === "success" ? (
                      <Badge color="success" className="font-weight-normal">
                        Payée
                      </Badge>
                    ) : (
                      <Badge color="danger" className="font-weight-normal">
                        Non payée
                      </Badge>
                    )}
                  </p>
                  <p className="my-0 client-name">
                    {order.clientName &&
                      order.clientName.split(" ")[
                        order.clientName.split(" ").length - 1
                      ]}{" "}
                    | <FontAwesomeIcon icon={faClock} />{" "}
                    {moment(order.createAt).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
                <TimerOrder createAt={order.createAt} />
              </ListGroupItem>
            </Animated>
          ))}
    </ListGroup>
  );
};
export default ListNewOrders;
