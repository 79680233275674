import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, ListGroupItem, ListGroup, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons'

const DetailsOrderModal = ({ isOpen, toggle, order }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Détails de la commande</ModalHeader>
            <ModalBody>
                <div className="order-details-client-info">
                    <div className="details-name-number">
                        <h4 className="mb-0">Kambale Kitoko Ivan</h4>
                        <p className="my-0 text-muted order-client-num"><FontAwesomeIcon icon={faPhoneAlt} />+243978308679</p>
                    </div>
                    <div className="text-right">
                        <h6 className="mb-0">#675422</h6>
                        <p className="my-0 text-muted order-date"><FontAwesomeIcon icon={faClock} />12/03/2020 14:30</p>
                    </div>
                </div>
                <div className="mt-3">
                    <h6>Détails</h6>
                    <div className="ml-3">
                        <ListGroup flush>
                            <ListGroupItem className="py-0 bg-transparent  order-details-item">
                                <p className="order-details-item-quantity my-0 text-right font-weight-bold">1x1</p>
                                <p className="text-muted order-details-item-name my-0 ml-2">Wali na maragi</p>
                                <p className="text-right my-0 font-weight-bold">10500 Fc</p>
                            </ListGroupItem>
                            <ListGroupItem className="py-0 bg-transparent  order-details-item">
                                <p className="order-details-item-quantity my-0 text-right font-weight-bold">1x2</p>
                                <p className="text-muted order-details-item-name my-0 ml-2">Frite & viande</p>
                                <p className="text-right my-0 font-weight-bold">4500 Fc</p>
                            </ListGroupItem>
                            <ListGroupItem className="py-0  bg-transparent order-details-item">
                                <p className="order-details-item-quantity my-0 text-right font-weight-bold">1x3</p>
                                <p className="text-muted order-details-item-name my-0 ml-2">Eau Tamu 33cl</p>
                                <p className="text-right my-0 font-weight-bold">3600 Fc</p>
                            </ListGroupItem>
                            <ListGroupItem className="py-0 bg-transparent  order-details-item">
                                <p className="order-details-item-quantity my-0 text-right font-weight-bold">1x1</p>
                                <p className="text-muted order-details-item-name my-0 ml-2">Fromage</p>
                                <p className="text-right my-0 font-weight-bold">1500 Fc</p>
                            </ListGroupItem>
                            <ListGroupItem className="py-0 bg-transparent  order-details-item">
                                <p className="order-details-item-quantity my-0 text-right font-weight-bold">1x1</p>
                                <p className="text-muted order-details-item-name my-0 ml-2">Pomme de terre & haricot</p>
                                <p className="text-right my-0 font-weight-bold">1000 Fc</p>
                            </ListGroupItem>
                            <ListGroupItem className="py-0 bg-transparent  order-details-item">
                                <p className="order-details-item-quantity my-0 text-right font-weight-bold">1x5</p>
                                <p className="text-muted order-details-item-name my-0 ml-2">Coca-cola 33cl</p>
                                <p className="text-right my-0 font-weight-bold">2200 Fc</p>
                            </ListGroupItem>

                        </ListGroup>
                        <div className="oder-details-total pt-2 pr-3">
                            <h6>Total</h6>
                            <h4 className="oder-details-total-value text-right">2000 Fc</h4>
                        </div>

                    </div>
                </div>
                <div className="mt-3">
                    <h6>Adresse de livraison</h6>
                    <div className="bg-white pl-3 py-1 ml-3">
                        <p className="text-muted">Rue kinshasa No 33, Gallery GTB No 120</p>
                    </div>
                </div>
                <div className="">
                    <h6>Informations sur le livreur</h6>
                    <div className="d-flex pl-3 py-1 ml-3">
                        <img src="/assets/images/livreur.jpg" alt="" height="48px" width="48px" className="rounded-circle" />
                        <div className="ml-2">
                            <p className="my-0 font-weight-bold">Katembo Solomona Richard</p>
                            <p className="text-muted my-0 order-rider-num"><FontAwesomeIcon icon={faPhoneAlt} /> +243978308679</p>
                        </div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter>
                <div className="text-right mt-3">

                    <Button size="sm" className="px-3 ml-2 btn-confirm font-weight-bold" onClick={toggle}>Fermer</Button>
                </div>
            </ModalFooter>

        </Modal>
    )
}

export default DetailsOrderModal
