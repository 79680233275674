import React, { useState } from "react";
import { Container, ListGroup, ListGroupItem } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faClock, faLock, faPrint, faUtensils, faUserCog, faCog} from "@fortawesome/free-solid-svg-icons"
import "./settings.scss";
import RestaurantInfo from "./restaurantInfo";
import SecurityInfo from "./securityInfo";

const Settings = ({ location, history }) => {
  const [currentPath, setcurrentPath] = useState(location.pathname);
  const [activeMenuIndex, setActiveMenuIndex] = useState(1);

  const handleActiveMenu = (index, path) => {
    setActiveMenuIndex(index);
    setcurrentPath(path);
    history.push(path);
  };
  return (
    <Container className="container-settings py-1" fluid>
      <div className="settings-header font-weight-bold"><FontAwesomeIcon icon={faCog}/> Paramettres</div>
      <div className="row mt-1">
        <div className="col-md-3">
          <div className="settings-menu px-1">
            <ListGroup flush>
              <ListGroupItem
                className={`setting-menu-item my-1 ${
                  currentPath === "/d/settings" && activeMenuIndex === 1
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 1, "/d/settings")}
              >
                <FontAwesomeIcon icon={faUserCog} className="icon-menu mr-3" />Restaurant
              </ListGroupItem>
              <ListGroupItem
                className={`setting-menu-item mb-1 ${
                  currentPath === "/d/settings/security"
                    ? "active-menu"
                    : activeMenuIndex === 2
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 2, "/d/settings/security")}
              >
                  <FontAwesomeIcon icon={faLock} className="icon-menu mr-3" />
                Sécurité
              </ListGroupItem>
              <ListGroupItem
                className={`setting-menu-item mb-1 ${
                  currentPath === "/d/settings/times"
                    ? "active-menu"
                    : activeMenuIndex === 3
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 3, "/d/settings/times")}
              >
                  <FontAwesomeIcon icon={faClock} className="icon-menu mr-3" />
                Temps
              </ListGroupItem>
              <ListGroupItem
                className={`setting-menu-item mb-1 ${
                  currentPath === "/d/settings/printer"
                    ? "active-menu"
                    : activeMenuIndex === 4
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 4, "/d/settings/printer")}
              >
                  <FontAwesomeIcon icon={faPrint} className="icon-menu mr-3" />
                Imprimante
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
        <div className="col-md-9 ">
          <Switch>
            <Route exact path="/d/settings" component={RestaurantInfo} />
            <Route path="/d/settings/security" component={SecurityInfo} />
          </Switch>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
