import React, { useState, useEffect } from "react";
import { Container, Alert } from "reactstrap";
import {
  faQuestionCircle,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, Route, Link } from "react-router-dom";
import { ListGroupItem, ListGroup } from "reactstrap";
import "./aide.scss";

const Aide = ({ location, history }) => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(1);
  const [currentPath, setcurrentPath] = useState(location.pathname);

  const handleActiveMenu = (index, path) => {
    setActiveMenuIndex(index);
    setcurrentPath(path);
    history.push(path);
  };

  return (
    <Container className="container-aide py-2" fluid>
      <div className="aider-header font-weight-bold ">
        <FontAwesomeIcon icon={faQuestionCircle} /> Guide d'utilisation
      </div>
      <div className="row mt-1">
        <div className="col-md-3">
          <div className="aide-menu px-1">
            <ListGroup flush>
              <ListGroupItem
                className={`aide-menu-item my-1 ${
                  currentPath === "/d/aide" && activeMenuIndex === 1
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 1, "/d/aide")}
              >
                Gestion des commandes
              </ListGroupItem>
              <ListGroupItem
                className={`aide-menu-item mb-1 ${
                  currentPath === "/d/aide/security"
                    ? "active-menu"
                    : activeMenuIndex === 2
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 2, "/d/aide/security")}
              >
                Gestion du menu
              </ListGroupItem>
              <ListGroupItem
                className={`aide-menu-item mb-1 ${
                  currentPath === "/d/aide/times"
                    ? "active-menu"
                    : activeMenuIndex === 3
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 3, "/d/aide/times")}
              >
                Gestion des paramètres
              </ListGroupItem>
              <ListGroupItem
                className={`aide-menu-item mb-1 ${
                  currentPath === "/d/aide/printer"
                    ? "active-menu"
                    : activeMenuIndex === 4
                    ? "active-menu"
                    : undefined
                }`}
                onClick={handleActiveMenu.bind(this, 4, "/d/aide/printer")}
              >
                Gestion du business
              </ListGroupItem>
            </ListGroup>
            <Alert color="warning">
              <FontAwesomeIcon icon={faInfoCircle} /> Pour toute autre question
              contacter nous{" "}
              <Link to="/d/contact">
                ici
              </Link>
            </Alert>
          </div>
        </div>
        <div className="col-9">
          <Switch>
            {/* <Route exact path="/d/aide" component={RestaurantInfo} />
            <Route path="/d/aide/security" component={SecurityInfo} /> */}
          </Switch>
        </div>
      </div>
    </Container>
  );
};

export default Aide;
