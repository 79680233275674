import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Button, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import moment from "moment";

const PreparingOrdersDetails = ({ order, toggle }) => {
  const [foods, setfoods] = useState([]);
  const [totalPrice, settotalPrice] = useState();

  // const [isSave, setisSave] = useState(false);
  // const restoId = localStorage.getItem("restaurantID");

  // const db = useFirestore()

  // const delegateOrder = () => {
  //   setisSave(true);
  //   db.update(`restaurants/${restoId}/orders/${order.id}`, {
  //     status: "delegated"
  //   })
  //     .then(res => {
  //       setisSave(false);
  //     })
  //     .catch(err => {
  //       setisSave(false);
  //     });
  // };

  useEffect(() => {
    let newfoods = [];
    let newTotal = 0;
    order.packages.forEach(food => {
      newfoods.push(JSON.parse(food));
      newTotal += JSON.parse(food).food.price * JSON.parse(food).count;
    });
    setfoods(newfoods);
    settotalPrice(newTotal);
    return () => {};
  }, [order]);

  return (
    <Animated animationIn="fadeIn">
      <div className=" px-3 pt-1 pb-2 order-details overflow-auto">
    
      <div className="order-details-client-info mt-2">
        <div className="details-name-number">
          <h5 className="mb-0 font-weight-normal">{order.clientName}</h5>
          <p className="my-0 text-muted order-client-num">
            <FontAwesomeIcon icon={faPhoneAlt} />
            {order.clientPhone}
          </p>
        </div>
        <div className="text-right">
          <h6 className="mb-0">#{order.orderNumber} {order.paymentStatus ==="success"?<Badge color="success" className="font-weight-normal">Payée</Badge>:<Badge color="danger" className="font-weight-normal">Non payée</Badge>}</h6>
          <p className="my-0 text-muted order-date">
            <FontAwesomeIcon icon={faClock} />
            Heure de livraison {moment(order.createAt+2100000).format("HH:mm")}
          </p>
        </div>
      </div>
      <div className="bg-white mt-1 p-3 foods-address-info">
      <div className="">
        {/* <h6 className="text-success">Détails</h6> */}
        <div className="ml-2 py-2 px-1">
          <ListGroup flush>
            {foods.map(food => (
              <ListGroupItem key={food.foodId} className="py-0 bg-transparent  order-details-item">
                <p className="order-details-item-quantity my-0 text-right font-weight-bold">
                  1x{food.count}
                </p>
                <p className="text-muted order-details-item-name my-0 ml-2">
                  {food.food.name}
                </p>
                <p className="text-right my-0">{food.food.price*food.count}<span className="font-weight-bold"> Fc</span></p>
              </ListGroupItem>
            ))}
          </ListGroup>
          <div className="oder-details-total pt-1 pr-3">
            <h6 className="mr-1">Total:</h6>
            <h5 className="oder-details-total-value text-right">{totalPrice}<span className="font-weight-bold"> Fc</span></h5>
          </div>
        </div>
        </div>
      </div>
      <div className="text-right mt-1 py-1">
        <Button
          size="sm"
          color="success"
          className="px-3 ml-2 font-weight-bold"
          onClick={toggle}
        >
          Déléguer un livreur
        </Button>
      </div>  
    </div>
    </Animated>
  );
};

export default PreparingOrdersDetails;
