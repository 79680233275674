import React, {useState} from "react";
import { Switch, Route } from "react-router-dom";
import CopyRight from "../copyRight/copyRight";
import EmailVerify from "./emailVerify";
import CodeVerify from "./codeVerify"
import PasswordReset from "./passwordReset";

const EditPassword = () => {

  return (
    <div>
      <Switch>
          <Route exact path="/password-forgotten" component={EmailVerify}/>
          <Route path="/password-forgotten/verify" component={CodeVerify} />
          {/* <Route path="/password-forgotten/new-password" component={PasswordReset}/> */}
      </Switch>
    </div>
  );
};
export default EditPassword;
