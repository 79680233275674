import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Badge,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { useFirestore } from "react-redux-firebase";
import "./home.scss";

const Riders = ({ isOpen, toggle, riders, order, orderId, resetActiveId }) => {
  const [activeRiderId, setactiveRiderId] = useState();
  const [riderUid, setriderUid] = useState()
  const [isSave, setisSave] = useState(false);
  const db = useFirestore();

  const setactiveRider = rider => {
    setactiveRiderId(rider.id);
    setriderUid(rider.user)
  };
  const delegateOrder = () => {
    setisSave(true); 
        db.update(`commands/${order.id}`, {
          riderId: activeRiderId,
          uidRider:riderUid,
          status: "delegated"
        }).then(res => {
          db.update(
            `restaurants/${localStorage.getItem("restaurantID")}/orders/${orderId}`,
            {
              riderId: activeRiderId,
              uidRider:riderUid,
              status: "delegated"
            }
          ).then(res => {
            setisSave(false);
            resetActiveId();
            toggle();
          })
          .catch(err => {
            setisSave(false);
            setactiveRiderId();
          }); 
      }).catch(err => {
        setisSave(false);
      });
  };
  return (
    <Modal isOpen={isOpen} scrollable>
      <ModalHeader className="riders-modal-header">
        <p className="my-0">
          <Badge className="mr-2">{riders.length}</Badge> Livreurs sont
          disponible dans votre milieu
        </p>
      </ModalHeader>
      <ModalBody className="riders-modal-body">
        <Row>
          <Col className="">
            <ListGroup className="list-riders">
              {riders.length === 0 && (
                <ListGroupItem className="empty-riders-city-list">
                  Pas de livreur disponible dans votre milieu
                </ListGroupItem>
              )}
              {riders &&
                riders.map(rider => (
                  <ListGroupItem
                    key={rider.id}
                    className={`list-riders-city py-1 ${
                      rider.id === activeRiderId ? "active-item" : null
                    }`}
                    onClick={
                      !isSave ? setactiveRider.bind(this, rider) : undefined
                    }
                  >
                    <img
                      src={
                        rider.avatar === ""
                          ? "/assets/images/avatar.png"
                          : rider.avatar
                      }
                      height="120px"
                      width="100%"
                      height="40px"
                      width="40px"
                      className="rounded-circle"
                    />
                    <div className="div-text-info ml-2">
                      <h6 className="my-0">{rider.name}</h6>
                      <p className="my-0 text-muted">
                        <FontAwesomeIcon icon={faPhoneAlt} /> {rider.phone}
                      </p>
                    </div>
                  </ListGroupItem>
                ))}
            </ListGroup>
          </Col>
          {/* <Col className="col-sm-12 col-md-8 bg-secondary"></Col> */}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="px-3 font-weight-bold"
          color="success"
          size="sm"
          disabled={isSave ? true : activeRiderId ? false : true}
          onClick={delegateOrder}
        >
          {isSave && <Spinner style={{ height: "16px", width: "16px" }} />}
          {"  "}
          Déléguer
        </Button>
        <Button
          className="px-3 font-weight-bold"
          // outline
          // color="warning"
          size="sm"
          disabled={isSave ? true : false}
          onClick={toggle}
        >
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Riders;
