import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Spinner,
  Alert,
  FormGroup,
  Label
} from "reactstrap";
import { useFirebase } from "react-redux-firebase";
import { useForm } from "react-hook-form";
import CopyRight from "../copyRight/copyRight";
import { Link } from "react-router-dom";
import "./login.scss";
import ToastMessage from "../toast/toast";

function EmailVerify({ history }) {
  const firebase = useFirebase();
  const [isExecute, setisExecute] = useState(false);
  const [errorMessage, seterrorMessage] = useState("Une erreur est survenue");
  const { register, handleSubmit } = useForm();
  const [isShowError, setisShowError] = useState(false);

  const toggleToastError = () => {setisShowError(false)};

  const resetPassword = data => {
    setisExecute(true);
    firebase
      .resetPassword(data.email)
      .then(res => {
        setisExecute(false);
        history.push("/password-forgotten/verify");
      })
      .catch(err => {
        setisExecute(false);
        console.log(err.code);
        switch (err.code) {
          case "auth/user-not-found":
            seterrorMessage("Utilisateur inconnu");
            break;
          case "auth/network-request-failed":
            seterrorMessage("Vérifier votre connexion internet");
            break;
          default:
            break;
        }
        setisShowError(true);
      });
  };
  return (
    <div className="container-email-change">
      <h5 className="mb-3">Mot de passe oublié?</h5>
      <Form
        className="form-email-verify"
        onSubmit={handleSubmit(resetPassword)}
      >
        <FormGroup>
          <Label className="mb-0" size="sm">
            Votre adresse mail
          </Label>
          <Input
            type="email"
            name="email"
            innerRef={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            })}
            disabled={isExecute ? true : false}
          />
        </FormGroup>
        <Button
          disabled={isExecute ? true : false}
          type="submit"
          color="warning"
          className="font-weight-bold text-dark"
        >
          {isExecute && (
            <Spinner color="light" style={{ height: "16px", width: "16px" }} />
          )}
          {"  "}
          Réinitialiser le mot de passe
        </Button>
        <Alert color="warning" className="mt-2">
          Si vous maitrisez bien votre adresse mail et votre mot de passe{" "}
          <Link to="/"> connectez-vous ici.</Link>
        </Alert>
      </Form>
      <CopyRight color="rgba(0,0,0,0.6)" />
      <ToastMessage
        className="bg-danger text-white"
        isShow={isShowError}
        toggle={toggleToastError}
        delay={5000}
        message={errorMessage}
        icon="autre"
      />
    </div>
  );
}

export default EmailVerify;
