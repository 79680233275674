import React, { useState, useEffect } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  Form,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Alert,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faClock,
  faInfoCircle,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import { Animated } from "react-animated-css";

const NewOrderDetails = ({ order, resetActiveId }) => {
  const [foods, setfoods] = useState([]);
  const [totalPrice, settotalPrice] = useState();
  const [isSave, setisSave] = useState(false);
  const restoId = localStorage.getItem("restaurantID");
  const [isOpenRefuseModal, setisOpenRefuseModal] = useState(false);
  const [isReject, setisReject] = useState(false);
  const { register, handleSubmit } = useForm();

  const db = useFirestore();

  const acceptOrder = () => {
    setisSave(true);

    db.update(`commands/${order.id}`, {
      status: "accepted",
      restaurantId: localStorage.getItem("restaurantID"),
      timeBefore: order.createAt + 2100000,
    })
      .then((res) => {
        db.update(`restaurants/${restoId}/orders/${order.id}`, {
          status: "accepted",
          restaurantId: localStorage.getItem("restaurantID"),
          timeBefore: order.createAt + 2100000,
        })
          .then((res) => {
            setisSave(false);
            resetActiveId();
          })
          .catch((err) => {
            setisSave(false);
          });
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  const rejectOrder = (data) => {
    setisReject(true);
    db.update(`commands/${order.id}`, {
      rejectReason: data.rejectReason + ". " + data.info,
      status: "rejected",
      restaurantId: localStorage.getItem("restaurantID"),
    })
      .then((res) => {
        db.update(`restaurants/${restoId}/orders/${order.id}`, {
          rejectReason: data.rejectReason + ". " + data.info,
          status: "rejected",
        })
          .then((res) => {
            setisReject(false);
            resetActiveId();
          })
          .catch((err) => {
            setisReject(false);
          });
      })
      .catch((err) => {
        setisReject(false);
      });
  };

  useEffect(() => {
    let newfoods = [];
    let newTotal = 0;
    order.packages.forEach((food) => {
      newfoods.push(JSON.parse(food));
      newTotal += JSON.parse(food).food.price * JSON.parse(food).count;
    });
    setfoods(newfoods);
    console.log(foods);
    settotalPrice(newTotal);
    return () => {};
  }, [order]);

  return (
    <Animated animationIn="fadeIn">
      <div className="px-3 pt-1 pb-2 order-details overflow-auto">
        <div className="order-details-client-info mt-2">
          <div className="details-name-number">
            <h5 className="mb-0 font-weight-normal">{order.clientName}</h5>
            <p className="my-0 text-muted order-client-num">
              <FontAwesomeIcon icon={faPhoneAlt} />
              {order.clientPhone}
            </p>
          </div>
          <div className="text-right">
            <h6 className="mb-0">
              #{order.orderNumber}{" "}
              {order.paymentStatus === "success" ? (
                <Badge color="success" className="font-weight-normal">
                  Payée
                </Badge>
              ) : (
                <Badge color="danger" className="font-weight-normal">
                  Non payée
                </Badge>
              )}
            </h6>
            <p className="my-0 text-muted order-date">
              <FontAwesomeIcon icon={faClock} />{" "}
              {moment(order.createAt).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>
        </div>
        <div className="bg-white mt-1 p-3 foods-address-info">
          <div>
            <div className="ml-2 py-2 px-1">
              <ListGroup flush>
                {foods.map((food) => (
                  <ListGroupItem
                    key={food.food.foodId}
                    className="py-0  bg-transparent order-details-item"
                  >
                    <p className="order-details-item-quantity my-0 text-right font-weight-bold">
                      1x{food.count}
                    </p>
                    <p className="text-muted order-details-item-name my-0 ml-2">
                      {food.food.name}
                    </p>
                    <p className="text-right my-0 font-weight-normal">
                      {food.food.price * food.count}
                      <span className="font-weight-bold"> Fc</span>
                    </p>
                  </ListGroupItem>
                ))}
              </ListGroup>
              <div className="oder-details-total pt-1 pr-3">
                <h6 className="mr-1">Total:</h6>
                <h5 className="oder-details-total-value text-right">
                  {totalPrice}
                  <span className="font-weight-bold"> Fc</span>
                </h5>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
              <span className="text-success">Adresse de livraison</span>
            </h6>
            <div className="pl-3 py-1 ml-3">
              <p className="text-muted">{order.destination}</p>
            </div>
          </div>
        </div>
        <div className="text-right mt-1 py-1">
          <Button
            size="sm"
            className="px-3  font-weight-bold"
            color="success"
            disabled={isSave ? true : false}
            onClick={acceptOrder}
          >
            {isSave && <Spinner style={{ height: "16px", width: "16px" }} />}
            {"  "}
            Accepter
          </Button>
          <Button
            size="sm"
            className="px-3 ml-2 font-weight-bold"
            disabled={isSave ? true : false}
            onClick={() => setisOpenRefuseModal(true)}
          >
            Refuser
          </Button>
        </div>
        <Modal isOpen={isOpenRefuseModal} centered>
          <Form onSubmit={handleSubmit(rejectOrder)}>
            <ModalBody>
              <Alert color="danger">
                <FontAwesomeIcon icon={faInfoCircle} /> Il faut avoir une bonne
                raison de refuser une commande. Veillez fournir la{" "}
                <span className="font-weight-bold text-dark">raison</span> afin
                de notifier le client{" "}
                <span className="font-weight-bold text-dark">{order.name}</span>{" "}
                du refus de sa commande.
              </Alert>
              <FormGroup>
                <Label className="font-weight-bold my-0" size="sm">
                  <span className="text-success">*</span>Raison du refus
                </Label>
                <Input
                  type="select"
                  bsSize="sm"
                  name="rejectReason"
                  innerRef={register({ required: true })}
                >
                  <option></option>
                  <option value="L'adresse de livraison hors zone">
                    Adresse de livraison hors zone d'intervention
                  </option>
                  <option value="Repas non disponible">
                    Repas non disponible
                  </option>
                  <option value="L'adresse de livraison n'est pas precise">
                    Adresse de livraison n'est pas precise
                  </option>
                </Input>
                <Label className="font-weight-bold my-0" size="sm">
                  Information supplémentaire
                </Label>
                <Input
                  type="textarea"
                  name="info"
                  bsSize="sm"
                  innerRef={register}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="font-weight-bold px-3"
                type="submit"
                size="sm"
                color="success"
                disabled={isReject ? true : false}
              >
                {isReject && (
                  <Spinner
                    style={{ height: "16px", width: "16px" }}
                    className="mr-2"
                  />
                )}
                Refuser
              </Button>
              <Button
                className="font-weight-bold px-3"
                size="sm"
                disabled={isReject ? true : false}
                onClick={() => setisOpenRefuseModal(false)}
              >
                Annuler
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </Animated>
  );
};
export default NewOrderDetails;
