import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ListGroup,
  ListGroupItem,
  ModalHeader,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPhoneAlt,
  faMapMarkerAlt,
  faBicycle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
function ModalMobileDeliverOrderDetails({
  isOpen,
  setisOpenModalDeliverOrderDetails,
  order,
  rider,
}) {
  const [foods, setfoods] = useState([]);
  const [totalPrice, settotalPrice] = useState();

  useEffect(() => {
    let newfoods = [];
    let newTotal = 0;
    order.packages.forEach((food) => {
      newfoods.push(JSON.parse(food));
      newTotal += JSON.parse(food).food.price * JSON.parse(food).count;
    });
    setfoods(newfoods);
    settotalPrice(newTotal);
    return () => {};
  }, [order]);

  const toggle = () => {
    setisOpenModalDeliverOrderDetails(false);
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <div className="details-name-number">
          <h5 className="mb-0">{order.clientName}</h5>
          <p className="my-0 text-muted" style={{ fontSize: "0.7rem" }}>
            <FontAwesomeIcon icon={faPhoneAlt} />
            {order.clientPhone}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="text-right">
          <h6 className="mb-0">
            #{order.orderNumber}{" "}
            {order.paymentStatus === "success" ? (
              <Badge color="success" className="font-weight-normal">
                Payée
              </Badge>
            ) : (
              <Badge color="danger" className="font-weight-normal">
                Non payée
              </Badge>
            )}
          </h6>
          <p className="mb-0 text-muted" style={{ fontSize: "0.6rem" }}>
            <Badge color="warning">
              <FontAwesomeIcon icon={faClock} /> Heure de livraison{" "}
              {moment(order.timeBefore).format("HH:mm")}
            </Badge>
          </p>
        </div>
        <div className="py-2">
          <ListGroup flush>
            {foods.map((food) => (
              <ListGroupItem
                key={food.food.foodId}
                className="py-0"
                style={{ display: "flex" }}
              >
                <p className="order-details-item-quantity my-0 text-right font-weight-bold">
                  1x{food.count}
                </p>
                <p className="text-muted my-0 ml-2" style={{ flex: "1" }}>
                  {food.food.name}
                </p>
                <p className="text-right my-0 font-weight-normal">
                  {food.food.price * food.count}
                  <span className="font-weight-bold"> Fc</span>
                </p>
              </ListGroupItem>
            ))}
          </ListGroup>
          <div className="pt-1 pr-3" style={{ display: "flex" }}>
            <h6 className="mb-0">Total:</h6>
            <h5 className="mb-0 text-right" style={{ flex: "1" }}>
              {totalPrice}
              <span className="font-weight-bold"> Fc</span>
            </h5>
          </div>
        </div>
        <div className="mt-1">
          <h6>
            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
            <span className="text-success">Adresse de livraison</span>
          </h6>
          <div className="pl-3 py-1 ml-3">
            <p className="text-muted">{order.destination}</p>
          </div>
        </div>
        <div className="mt-2">
          <h6>
            <FontAwesomeIcon icon={faBicycle} />{" "}
            <span className="text-success">Livreur</span>
          </h6>
          <div className="d-flex pl-3 py-2 ml-3">
            <img
              src={rider ? rider.avatar : ""}
              alt=""
              height="48px"
              width="48px"
              className="rounded-circle"
            />
            <div className="ml-2">
              <p className="my-0 font-weight-bold">{rider && rider.name}</p>
              <p className="text-muted my-0 order-rider-num">
                <FontAwesomeIcon icon={faPhoneAlt} /> {rider && rider.phone}
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalMobileDeliverOrderDetails;
